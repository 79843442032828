$colorDark: #363d42;
$colorMain: #2caaff;
$colorMain2: #75c7fd;
$colorMainBack: #eff9ff;
$colorMainBack2: #ddeffb;
$colorAlert: #f25130;
$colorAlertBack: #fff4f2;
$colorAlertBack2: #fae3df;
$colorSuccess: #25c374;
$colorGrey: #9da8b4;
$mediaM: 700px;
$mediaMEnd: calc($mediaM + 1px);
$trans: all 0.3s ease-in-out;
$orderStatuses: (
    draft: $colorGrey,
    wait: $colorMain,
    in-payment: #ff9c20,
    in-search: #ffb400,
    set-crew: #98d400,
    in-process: #66ca49,
    completed: $colorSuccess,
    cancelled: #9da8b4,
    unsetCrew: #f25130,
    error-payment: #f25130
);
