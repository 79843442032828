.profileInner {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 100%;

    &._full {
        height: 100%;
    }

    &__head {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 58rem;

        @include mobile {
            align-items: center;
            height: auto;
            margin-bottom: 24rem;
        }

        &Filter {
            display: flex;
            align-items: center;
            height: 20rem;
            margin-left: 20rem;

            @include mobile {
                height: auto;
                margin-left: auto;
            }
        }

        &Actions {
            margin-left: auto;
            padding-top: 2rem;
        }
    }

    &__title {
        font-size: 20.5rem;

        @include mobile {
            padding-right: 20rem;
            font-size: 18rem;
        }

        &Inner,
        span {
            display: inline-block;
            font-weight: 300;
            font-size: 20.5rem;
            line-height: 0.55;

            @include mobile {
                font-size: 18rem;
            }
        }

        & i {
            display: inline-block;
            width: 13rem;
            height: 13rem;
            margin: 0 14rem;
            transform: translate(0, 2rem);

            @include icon($colorDark);
        }
    }

    &__content {
        width: 100%;

        &._full {
            height: calc(var(--wheight) - 70rem - 20rem - 58rem);

            @include mobile {
                height: calc(var(--wheight) - 90rem - 22rem);
            }
        }

        &:not(._full) {
            min-height: calc(var(--wheight) - 70rem - 20rem - 58rem);

            @include mobile {
                min-height: 300rem;
            }
        }
    }

    &._orders &__content {
        position: relative;
        z-index: 1;
        width: calc(100% + 100rem);
        margin-bottom: -20rem;
        margin-left: -50rem;
        padding: 0 50rem 20rem;
        overflow: hidden;

        @include mobile {
            margin-bottom: -28rem;
            padding-bottom: 28rem;
        }
    }

    &__loader {
        @include loader($colorMain);

        z-index: 10;

        &._orders {
            padding-top: 60rem;
        }
    }

    &__card {
        width: 100%;
        margin-bottom: 20rem;
    }

    &._orders ._INFOBLOCKWRAPPER {
        padding-top: 60rem;

        @include mobile {
            height: calc(var(--wheight) - 150rem - 102rem - 74rem - 112rem);
            padding-top: 0;
        }
    }

    &__actions {
        width: 100%;
        margin-bottom: 40rem;

        @include mobile {
            flex-direction: column;
            margin-top: auto;
            margin-bottom: 0;
            padding-top: 32rem;
        }

        &Button {
            align-items: center;

            &Inner {
                width: 240rem;
                height: 50rem;
                margin-right: 16rem;

                @include mobile {
                    width: 100%;
                    height: 56rem;
                    margin-right: 0;
                }
            }

            &Support {
                font-weight: 300;
                font-size: 12.5rem;
                line-height: 1.4;

                @include mobile {
                    display: none;
                }
            }
        }

        &Error {
            width: 100%;

            &._parent:not(._empty) {
                margin-top: 20rem;
            }

            & .error {
                text-align: left;
            }
        }
    }

    &__users {
        width: 100%;
        margin-top: 20rem;

        @include mobile {
            margin-top: 40rem;
        }
    }

    &__orders {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        &Tabs {
            position: relative;
            z-index: 1;
            width: 100%;
            margin-bottom: 20rem;
            overflow: hidden;
            background: rgba(#fff, 0.72);
            border-radius: 20rem;
            transition: $trans;

            &._hide {
                opacity: 0;
            }

            &Inner {
                width: 100%;
                padding: 8rem 18rem;
            }

            &Support {
                margin-right: 6rem;
                padding-top: 5rem;
                font-weight: 500;
                font-size: 15rem;
            }

            &Items {
                flex-wrap: wrap;
                width: calc(100% - 100rem);
                margin-bottom: -6rem;
                margin-left: auto;
            }

            &Item {
                margin: 0 6rem 8rem 0;
                padding: 6rem 12rem;
                font-weight: 300;
                font-size: 13rem;
                background: #f1f9ff;
                border-radius: 360rem;

                @include hover {
                    &:hover {
                        color: #fff;
                        font-weight: 500;
                        background: $colorMain2;
                    }
                }

                &:not(._visible) {
                    opacity: 0;
                    pointer-events: none;
                }

                @include button(0.96);

                &._current {
                    color: #fff;
                    font-weight: 600;
                    background: $colorMain;
                }
            }

            &Btn {
                position: absolute;
                top: 8rem;
                right: 8rem;
                z-index: 2;
                width: 26rem;
                height: 26rem;
                background: #f1f9ff;
                border-radius: 50%;

                @include hover {
                    &:hover {
                        background: $colorMain !important;

                        &::after {
                            border-top-color: #fff;
                            border-right-color: #fff;
                        }
                    }
                }

                @include button(0.96);

                &::after {
                    display: block;
                    margin: auto;
                    border: 2rem solid transparent;
                    border-top-color: $colorMain;
                    border-right-color: $colorMain;
                    transform: translate(0, -1rem) rotate(135deg);
                    transition: $trans;
                    content: "";
                }
            }

            &._show &Btn {
                background: #dff2ff;
                transform: rotate(-180deg);
            }
        }
    }

    &:not(._ready) &__orders {
        opacity: 0;
    }

    &__order {
        z-index: 2;
        width: 100%;

        &._hide {
            // z-index: 1;
            // margin-top: -240rem;
        }
    }

    &__orders._static &__order:not(._last) {
        margin-bottom: 12rem;

        @include mobile {
            margin-bottom: 20rem;
        }
    }

    &__newOrder {
        width: 100%;
        margin-top: 22rem;
        margin-bottom: 24rem;

        @include mobile {
            height: 56rem;
        }
    }

    &__mobHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 32rem;
    }

    &__mobNav {
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;

        &Item {
            position: relative;
            width: calc(50% - 6rem);
            margin-bottom: 12rem;
            padding: 20rem;
            background: #fff;
            border: 2rem solid #fff;
            border-radius: 24rem;

            @include button(0.95);

            &._logout {
                background: #ffede9;
            }

            &Icon {
                width: 24rem;
                height: 24rem;
                margin-bottom: 34rem;

                @include icon(#b9c5d3);
            }

            &._company &Icon {
                width: 22rem;
                height: 22rem;
            }

            &Loader {
                position: absolute;
                top: 28rem;
                right: 28rem;
                width: 32rem;
                height: 32rem;

                @include loader($colorMain);

                @include mobile {
                    top: 20rem;
                    right: 20rem;
                    width: 26rem;
                    height: 26rem;
                }
            }

            &._logout &Icon {
                @include icon($colorAlert);
            }

            &._logout &Loader {
                @include loader($colorAlert);
            }

            &Title {
                margin-bottom: 6rem;
                font-weight: 500;
                font-size: 18rem;
                white-space: nowrap;
            }

            &._logout &Title {
                color: $colorAlert;
            }

            &Description {
                font-weight: 300;
                font-size: 16rem;
            }

            &Counter {
                position: absolute;
                top: 16rem;
                right: 16rem;
            }
        }
    }

    &__button {
        width: 100%;
        margin-top: 20rem;
    }
}
