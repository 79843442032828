.profileChats {
    width: 100%;
    height: 100%;

    &__block {
        height: 100%;

        @include mobile {
            height: auto;
        }

        &._list {
            width: 310rem;

            @include mobile {
                width: 100%;
            }
        }

        &._content {
            width: calc(100% - 310rem - 2rem);
            margin-left: auto;
        }
    }

    &__head {
        width: 100%;
        height: 84rem;
        padding: 32rem;
        background: #fff;
        border-radius: 26rem 2rem 2rem;

        @include mobile {
            padding: 0;
            background: none;
            border-radius: 0;
        }

        &Title {
            font-weight: 500;
            font-size: 18rem;

            &Inner {
                display: inline-block;
                font-weight: 300;
                font-size: 18rem;
                line-height: 0.55;
                white-space: nowrap;
            }
        }
    }

    &__list {
        position: relative;
        z-index: 1;
        width: 100%;
        height: calc(100% - 84rem - 2rem);
        margin-top: 2rem;
        overflow: hidden;
        background: #f9fbfc;
        border-radius: 2rem 2rem 2rem 26rem;

        @include mobile {
            height: auto;
            margin-top: 0;
            overflow: visible;
            background: none;
            border-radius: 0;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 38rem;
        background: #f9fbfc;
        border-radius: 2rem 26rem 26rem 2rem;

        @include mobile {
            padding: 0;
            background: none;
            border-radius: 0;
        }

        &._full {
            padding: 0;
            background: none;
            border-radius: 0;
        }

        &Inner {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &Window {
            width: 100%;
            height: 100%;
        }
    }
}
