.cursorSupport {
    position: absolute;
    z-index: 10001;
    pointer-events: none;

    &__item {
        position: absolute;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._bottom {
                transform: translate(0, 3rem);
            }

            &._top {
                transform: translate(0, -3rem);
            }
        }

        &::before {
            position: absolute;
            width: 8rem;
            height: 8rem;
            background: $colorDark;
            content: "";
        }

        &._bottom::before {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &._top::before {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
        }

        &._upper &Inner {
            padding: 6rem 10rem 5rem;
            font-weight: 500;
            font-size: 12rem;
            letter-spacing: 0.1rem;
            text-transform: uppercase;

            & b {
                font-size: 12rem;
                letter-spacing: 0.1rem;
            }
        }

        &._wide &Inner {
            padding: 8rem 14rem 7rem;
            line-height: 1.3;
        }

        &Inner {
            padding: 6rem 11rem 7rem;
            color: #fff;
            font-weight: 300;
            font-size: 14rem;
            line-height: 1.25;
            white-space: nowrap;
            text-align: center;
            background: $colorDark;
            border-radius: 13rem;

            & b {
                color: #fff;
                font-weight: 500;
                font-size: 14rem;
                line-height: 1.25;
            }
        }
    }
}
