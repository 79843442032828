.profileCard {
    width: 100%;
    height: 100%;

    @include mobile {
        padding: 18rem;
        background: #fff;
        border-radius: 24rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);
    }

    &__head {
        align-items: center;
        width: 100%;
        height: 74rem;
        margin-bottom: 1rem;
        padding: 20rem 34rem;
        background: #fff;
        border-radius: 22rem 22rem 2rem 2rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            margin-bottom: 12rem;
            padding: 6rem 10rem;
            background: none;
            border-radius: 0;
            box-shadow: none;
        }

        &Title {
            font-size: 17.5rem;

            @include mobile {
                font-size: 18rem;
                line-height: 1.4;
            }

            &Inner {
                display: inline-block;
                font-weight: 300;
                font-size: 17.5rem;
                line-height: 0.5;
                white-space: nowrap;

                @include mobile {
                    font-size: 18rem;
                }
            }
        }

        &Actions {
            align-items: center;
            margin-left: auto;

            @include mobile {
                align-items: flex-start;
                width: 100%;
                margin: 20rem 0 0;
            }
        }

        &Button {
            @include mobile {
                width: 100%;
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        padding: 27rem 34rem 30rem;
        background: #fff;
        border-radius: 22rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);

        @include mobile {
            padding: 0;
            background: none;
            border-radius: 0;
            box-shadow: none;
        }

        &._hidden {
            z-index: 1;
            overflow: hidden;
        }

        &Inner {
            width: 100%;

            @include mobile {
                position: relative;
            }
        }
    }

    &__loader {
        @include loader($colorMain);
    }

    &__head + &__content {
        border-radius: 2rem 2rem 22rem 22rem;

        @include mobile {
            border-radius: 0;
        }
    }

    &__fields {
        width: 100%;

        @include mobile {
            flex-direction: column;
        }
    }

    &__field {
        @include mobile {
            & + & {
                margin-top: 16rem;
            }
        }

        &Support {
            margin-bottom: 9rem;
            padding-left: 12rem;
            font-weight: 300;
            font-size: 15rem;

            @include mobile {
                margin-bottom: 8rem;
                font-size: 16rem;
            }
        }

        &Box {
            width: 100%;
            height: 50rem;

            @include mobile {
                height: 54rem;
            }
        }
    }

    &._settings &__field {
        width: calc(calc(100% - 23% - 12rem) / 3);
        margin-right: 12rem;

        @include mobile {
            width: 100%;
            margin-right: 0;
        }

        &._email,
        &._phone {
            width: 23%;
            margin-right: 12rem;

            @include mobile {
                width: 100%;
                margin-right: 0;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__button {
        width: 100%;
        margin-top: 20rem;
    }
}
