.publicBlog {
    position: relative;
    width: 100%;

    &__logo {
        margin: 0 auto 100rem;
    }

    &__head {
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 32rem;

        @include mobile {
            position: relative;
            flex-direction: column;
            justify-content: flex-start;
        }

        &Block {
            @include mobile {
                width: 100%;
            }
        }

        &Title {
            // margin-bottom: 24rem;
            color: $colorDark;
            font-size: 40rem;

            @include mobile {
                margin-bottom: 24rem;
                font-size: 32rem;
            }
        }

        &TabsWrapper {
            @include mobile {
                width: calc(100% + 40rem);
                margin-left: -20rem;
                overflow-x: auto;
                border-bottom: 1px solid rgba(#b4bec8, 0.5);
            }
        }

        &Tabs {
            align-items: center;
            transition: $trans;

            @include mobile {
                display: inline-flex;
                padding: 0 20rem;
            }

            &:not(._show) {
                opacity: 0;
            }
        }

        &Tab {
            position: relative;
            padding-bottom: 8rem;
            color: $colorDark;
            font-weight: 300;
            font-size: 18rem;
            line-height: 1.3;
            transition: $trans;

            @include mobile {
                padding-bottom: 12rem;
                font-size: 18rem;
                white-space: nowrap;
            }

            &._current {
                font-weight: 400;

                &::after {
                    opacity: 1;
                }
            }

            & + & {
                margin-left: 24rem;

                @include mobile {
                    margin-left: 18rem;
                }
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2rem;
                background: $colorMain;
                border-radius: 10rem 10rem 0 0;
                opacity: 0;
                transition: $trans;
                content: "";

                @include mobile {
                    height: 3rem;
                }
            }
        }

        &Search {
            width: 310rem;
            height: 50rem;

            @include mobile {
                width: 100%;
                height: 56rem;
            }
        }
    }

    &__loader._LOADER {
        height: 400rem;

        @include loader($colorMain);

        @include mobile {
            height: 300rem;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        transition: $trans;
    }

    &:not(._ready) &__articles {
        opacity: 0;
    }

    &__articles {
        position: relative;
        width: calc(100% + 16rem);
        margin-left: -8rem;
        transition: $trans;

        @include mobile {
            width: 100%;
            margin-left: 0;
        }

        &._static {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            @include mobile {
                flex-flow: column nowrap;
                align-items: center;
            }
        }
    }

    &__article {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: calc(100% / 3);
        height: 340rem;
        padding: 0 8rem;
        transition: $trans;

        @include mobile {
            width: 100%;
            height: auto;
            padding: 0;
        }

        &._main {
            width: calc(calc(100% / 3) * 2);

            @include mobile {
                width: 100%;
            }
        }

        &._info {
            z-index: 1;

            @include mobile {
                height: 350rem;
            }
        }

        &Inner {
            position: relative;
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        &:not(._show) &Inner {
            opacity: 0;
        }
    }

    &__articles._static &__article {
        position: relative;
        margin-bottom: 20rem;

        &._info {
            flex: 1 auto;
        }

        &:not(._show) {
            display: none;
        }
    }

    &__loader._SCROLL {
        top: auto;
        bottom: 0;
        height: 270rem;
        padding: 170rem 0 20rem;
    }
}
