.filterButton {
    position: relative;
    display: flex;
    align-items: center;
    height: 44rem;
    padding: 6rem 18rem;
    font-size: 15rem;
    background: #fff;
    border-radius: 12rem;

    @include mobile {
        height: 50rem;
        padding: 8rem 20rem;
        font-size: 16rem;
        border-radius: 16rem;
    }

    @include hover {
        &:hover {
            color: $colorMain;

            @include icon($colorMain);
        }
    }

    &._grey {
        background: #f3f7f9;
    }

    &._short {
        justify-content: center;
        width: 36rem;
        height: 36rem;
        padding: 6rem;
        border-radius: 10rem;

        @include mobile {
            width: 44rem;
            height: 44rem;
            padding: 10rem;
            border-radius: 14rem;
        }
    }

    &._full {
        width: 100% !important;
        height: 100% !important;
    }

    @include button(0.96);

    &__counter {
        width: 100%;
        height: 18rem;
        transition: $trans;

        @include mobile {
            height: 22rem;
        }

        &._empty {
            opacity: 0;
        }

        &._parent {
            position: absolute;
            top: -4rem;
            right: -4rem;
            z-index: 2;
            min-width: 18rem;
            background: $colorMain;
            border-radius: 50%;

            @include mobile {
                top: -7rem;
                min-width: 22rem;
            }
        }

        &Inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding-top: 1rem;
            color: #fff;
            font-weight: 600;
            font-size: 12rem;
            text-align: center;

            @include mobile {
                font-size: 14rem;
            }
        }
    }

    &__icon {
        width: 16rem;
        height: 16rem;
        margin-right: 10rem;

        @include mobile {
            width: 18rem;
            height: 18rem;
            margin-right: 8rem;
        }
    }

    &._short &__icon {
        margin-right: 0;
    }
}
