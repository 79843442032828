.avatar {
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 50%;

    &__inner {
        display: flex;
        width: 100%;
        height: 100%;
        background: #f3f7f9;
        transition: $trans;
    }

    &__text {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: $colorGrey;
        font-size: 16rem;
        transition: $trans;
    }

    &__image {
        width: 70%;
        height: 70%;
        margin: auto;
    }
}
