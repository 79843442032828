.orderContacts {
    position: relative;
    z-index: 1;
    width: 100%;

    &__point {
        width: 100%;

        & + & {
            margin-top: 16rem;

            @include mobile {
                margin-top: 12rem;
            }
        }

        &Head {
            position: relative;
            align-items: center;
            width: 100%;
            height: 76rem;
            margin-bottom: 2rem;
            padding: 23rem 28rem 23rem 66rem;
            font-size: 16rem;
            line-height: 1.3;
            background: #fff;
            border-radius: 16rem 16rem 2rem 2rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);

            @include mobile {
                align-items: flex-start;
                height: auto;
                padding: 14rem 24rem 14rem 56rem;
                font-size: 18rem;
                border-radius: 24rem 24rem 2rem 2rem;
            }
        }

        &Number {
            position: absolute;
            top: 50%;
            left: 28rem;
            align-items: center;
            justify-content: center;
            width: 26rem;
            height: 26rem;
            padding-top: 1rem;
            font-size: 16rem;
            background: #e7f5ff;
            border-radius: 8rem;
            transform: translate(0, -50%);

            @include mobile {
                top: 14rem;
                left: 20rem;
                width: 24rem;
                height: 24rem;
                font-size: 15rem;
                border-radius: 8rem;
                transform: translate(0, 0);
            }
        }

        &Content {
            align-items: center;
            width: 100%;
            padding: 25rem 28rem;
            background: #fff;
            border-radius: 2rem 2rem 16rem 16rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);

            @include mobile {
                padding: 18rem 20rem;
                border-radius: 2rem 2rem 24rem 24rem;
            }
        }

        &Support {
            position: relative;
            margin-right: 12rem;
            padding-left: 23rem;
            font-size: 16rem;

            @include mobile {
                display: none;
                margin-right: 0;
                margin-bottom: 22rem;
                padding-left: 26rem;
                font-size: 18rem;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 18rem;
                height: 18rem;
                transform: translate(0, -50%);

                @include mobile {
                    width: 20rem;
                    height: 20rem;
                }

                @include icon($colorMain);
            }
        }

        &Input {
            height: 44rem;

            @include mobile {
                width: 100%;
                height: 44rem;
            }

            &._name {
                width: 210rem;
                margin-right: 10rem;

                @include mobile {
                    width: 100%;
                    margin-right: 8rem;
                }
            }

            &._phone {
                width: 184rem;
                margin-right: 10rem;

                @include mobile {
                    width: 100%;
                    margin-right: 0;
                }
            }

            &._time {
                width: 220rem;
            }

            & .input {
                background: #f1f9ff;

                @include mobile {
                    border-radius: 12rem;
                }
            }

            & .input__support,
            & .input__field {
                @include mobile {
                    padding: 8rem 10rem !important;
                    font-size: 16rem !important;
                }
            }
        }
    }
}
