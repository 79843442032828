.switch {
    display: block;
    width: 100%;
    height: 100%;

    &__inner {
        position: relative;
        width: 42rem;
        height: 26rem;
        background: #dde9ef;
        border-radius: 360rem;
        transition: $trans;

        @include mobile {
            width: 50rem;
            height: 30rem;
        }

        @include hover {
            &:hover {
                background: #ccdfe9;
            }
        }

        @include button;
    }

    &__point {
        position: absolute;
        top: 50%;
        left: 6rem;
        width: 14rem;
        height: 14rem;
        background: #fff;
        border-radius: 50%;
        transform: translate(0, -50%);
        transition: $trans;

        @include mobile {
            left: 6rem;
            width: 18rem;
            height: 18rem;
        }
    }

    &__input {
        display: none;
    }

    &__input:checked ~ &__inner {
        background: $colorMain;

        @include hover {
            &:hover {
                background: $colorMain2;
            }
        }
    }

    &__input:checked ~ &__inner &__point {
        left: calc(100% - 6rem);
        transform: translate(-100%, -50%);

        @include mobile {
            left: calc(100% - 6rem);
        }
    }
}
