.publicLegal {
    width: 100%;
    height: 100%;

    &__inner {
        align-items: center;
    }

    &__navWrapper {
        position: absolute;
        top: 172rem;
        z-index: 2;
        pointer-events: none;
    }

    &__nav {
        width: 300rem;
        pointer-events: visible;
    }

    &__loader {
        @include loader($colorMain);
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
