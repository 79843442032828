.infoBlock {
    width: 100%;
    height: 100%;
    padding: 32rem;

    @include mobile {
        padding: 20rem 0;
    }

    &._white {
        background: rgba(#fff, 0.8);
        border-radius: 32rem;

        @include mobile {
            padding: 20rem;
        }
    }

    &__inner {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__icon {
        width: 36rem;
        height: 36rem;
        margin-bottom: 12rem;
        object-fit: contain;
    }

    &__title {
        margin-bottom: 8rem;
        color: $colorGrey;
        font-size: 18rem;
        text-align: center;

        @include mobile {
            font-size: 18rem;
        }
    }

    &__description {
        color: $colorGrey;
        font-weight: 300;
        font-size: 15rem;
        line-height: 1.4;
        text-align: center;

        @include mobile {
            font-size: 16rem;
        }
    }
}
