.calendar {
    width: 100%;
    height: 100%;

    &._filter {
        position: relative;
        z-index: 1;
        padding: 20rem;
        overflow: hidden;
        background: #f2f7fa;
        border-radius: 16rem;

        @include mobile {
            padding: 20rem;
            border-radius: 24rem;
        }
    }

    &._filter &__head {
        @include mobile {
            padding: 10rem 12rem 0;
        }
    }

    &__head {
        position: relative;
        z-index: 2;
        align-items: center;
        width: 100%;
        margin-bottom: 27rem;

        @include mobile {
            padding: 12rem 14rem 0;
        }

        &Title {
            &Inner {
                font-size: 18rem;
                white-space: nowrap;
            }
        }

        &Actions {
            margin-left: auto;
        }

        &Action {
            width: 16rem;
            height: 16rem;

            @include mobile {
                width: 20rem;
                height: 20rem;
            }

            @include hover {
                &:hover {
                    @include icon($colorMain);
                }
            }

            @include button(0.9);

            & + & {
                margin-left: 13rem;

                @include mobile {
                    margin-left: 12rem;
                }
            }
        }
    }

    &._filter &__headTitleInner {
        font-size: 17rem;

        @include mobile {
            font-size: 18rem;
        }
    }

    &__days {
        position: relative;
        z-index: 2;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 12rem;
    }

    &__day {
        width: calc(100% / 7);
        font-size: 16rem;
        text-align: center;

        @include mobile {
            font-size: 18rem;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;

        &._static {
            display: flex;
            flex-wrap: wrap;
        }

        &Block {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &:not(._show) {
                &._prev {
                    transform: translate(-50rem, 0);
                }

                &._next {
                    transform: translate(50rem, 0);
                }
            }
        }

        &Item {
            align-items: center;
            width: calc(100% / 7);
            margin-bottom: 4rem;

            @include mobile {
                padding: 0 2rem;
            }
        }

        &Date {
            align-items: center;
            justify-content: center;
            width: 32rem;
            height: 32rem;
            padding-top: 2rem;
            color: $colorDark;
            font-size: 16rem;
            border: 1rem solid transparent;
            border-radius: 9rem;
            transition: $trans;

            @include mobile {
                width: 100%;
                height: 34rem;
                font-size: 18rem;
                border-radius: 10rem;
            }

            &:not(._active) {
                color: $colorGrey;
            }

            &._active {
                background: #f5f7fa;

                @include hover {
                    &:hover {
                        color: #fff;
                        background: $colorMain !important;
                    }
                }

                @include button(0.95);
            }

            &._now {
                color: $colorMain;
                font-weight: 500;
            }

            &._current {
                color: #fff;
                background: $colorMain;

                &:not(._active) {
                    opacity: 0.5;
                }
            }

            &._inside {
                color: $colorDark;
                border-color: rgba($colorMain, 0.72);

                &:not(._active) {
                    opacity: 0.5;
                }
            }

            &._disabled {
                opacity: 0.5;
            }
        }
    }

    ._filter &__contentDate,
    &._filter &__contentDate {
        font-weight: 300;

        @include mobile {
            width: 100%;
            height: 32rem;
        }

        &._active {
            background: #fff;
        }

        &._now {
            font-weight: 500;
        }

        &._current {
            font-weight: 500;
            background: $colorMain;
        }
    }

    &__currents {
        align-items: center;
        margin-top: 14rem;
        margin-bottom: 14rem;

        @include mobile {
            padding: 0 14rem;
        }
    }

    &._filter &__currents {
        margin-bottom: 0;

        @include mobile {
            padding: 0 12rem;
        }
    }

    &__current {
        position: relative;
        z-index: 2;
        align-items: center;

        & + & {
            margin-left: 8px;
        }

        &Support {
            font-weight: 300;
            font-size: 16rem;

            @include mobile {
                font-size: 16rem;
            }
        }

        &Input {
            width: 90rem;
            margin-left: 4rem;

            @include mobile {
                width: 110rem;
            }
        }

        &._time &Input {
            width: 60rem;
        }
    }

    &._filter &__current {
        &Input {
            width: 100rem;
            height: 26rem;
            margin-left: 7rem;
            padding: 1rem 6rem 0;
            background: #fff;
            border-radius: 7rem;

            @include mobile {
                width: 90rem;
                height: 26rem;
                margin-left: 10rem;
                padding: 1rem 5rem 0;
                border-radius: 8rem;
            }
        }
    }

    &._filter &__current._time &__currentInput {
        @include mobile {
            width: 90rem;
        }
    }

    &__error {
        width: 100%;
        text-align: center;

        &:not(._empty) {
            margin-bottom: 10rem;
        }
    }

    &__button {
        width: 100%;
        height: 46rem;

        @include mobile {
            height: 64rem;
        }
    }
}
