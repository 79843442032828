.popup {
    position: relative;
    width: 100%;
    height: 100%;

    &__head {
        position: absolute;
        top: 67rem;
        z-index: 3;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 67rem;
        pointer-events: none;

        @include mobile {
            top: 0;
            height: 80rem;
            padding: 0;
        }
    }

    &._orderDetails &__head {
        top: 48rem;

        @include mobile {
            top: 0;
        }
    }

    &._headCenter &__head {
        top: 70rem;
        justify-content: center;
    }

    &__logo {
        z-index: 2;
        width: 170rem;

        @include mobile {
            width: 214rem;
        }
    }

    &._headCenter &__close {
        display: none;
    }

    &__close {
        align-items: center;
        color: $colorDark;
        font-size: 15.5rem;
        pointer-events: visible;

        &::before {
            width: calc(100% + 20rem);
            height: calc(100% + 20rem);
            border-radius: 10rem;
        }

        @include button;

        &Btn {
            width: 20rem;
            height: 20rem;
            margin-left: 8rem;
            background: $colorMain;
            border-radius: 6.5rem;

            @include mobile {
                position: relative;
                width: 56rem;
                height: 56rem;
                background: #fff;
                border-radius: 14rem;
            }

            &Icon {
                display: block;
                width: 8rem;
                height: 8rem;
                margin: auto;
                transition: $trans;
                will-change: transform;

                @include icon(#fff);

                @include mobile {
                    width: 16rem;
                    height: 16rem;

                    @include icon($colorDark);
                }
            }

            &Item {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                width: 100%;
                height: 100%;
                transition: $trans;

                &::before {
                    width: 24rem;
                    height: 2rem;
                    margin: auto;
                    background: $colorMain;
                    border-radius: 360rem;
                    content: "";
                }

                &:nth-child(1) {
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    transform: rotate(-45deg);
                }
            }
        }

        @include hover {
            &:hover {
                color: $colorMain;
            }

            &:hover &BtnIcon {
                transform: rotate(90deg);
            }
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__pages {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    &._orderDetails &__page {
        padding: 0;
    }

    &__page {
        @extend ._NOSCROLL;

        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 50rem 0;
        overflow: hidden;
        overflow-y: auto;

        @include mobile {
            padding: 100rem 0 50rem;
        }

        &._start {
            // justify-content: flex-start;
        }

        &:not(._show, ._end) {
            &._prev {
                transform: translate(-50rem, 0);
            }

            &._next {
                transform: translate(50rem, 0);
            }
        }
    }

    &._headCenter &__page {
        padding-top: 100rem;
    }

    &__mob &__box {
        @include mobile {
            padding: 0;
        }
    }

    &__box {
        margin: auto;

        &._success {
            width: 340rem;
        }

        &._login {
            width: 368rem;
        }

        &._registration {
            width: 560rem;
        }

        &._alert {
            width: 350rem;
        }

        &._dateWTime {
            width: 334rem;
        }

        &._filter {
            width: 380rem;
        }

        &._wallets {
            width: 580rem;
        }

        &._walletsCard {
            width: 380rem;
        }

        &._logs {
            width: 800rem;
        }

        @include mobile {
            width: 100% !important;
            padding: 0 24rem;
        }

        &Head {
            position: relative;
            z-index: 1;
            width: 100%;
            margin-bottom: 2rem;
            padding: 36rem 40rem;
            background: #fff;
            border-radius: 32rem 32rem 2rem 2rem;
            box-shadow: 0 4rem 8rem rgba(#000, 0.035);

            @include mobile {
                margin-bottom: 32rem;
                padding: 0;
                background: none;
                border-radius: 0;
                box-shadow: none;
            }
        }

        &._filter &Head {
            padding-top: 26rem;
            padding-bottom: 24rem;
        }

        &Content {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 36rem 40rem;
            overflow: hidden;
            background: #fff;
            border-radius: 32rem;
            box-shadow: 0 4rem 8rem rgba(#000, 0.035);

            @include mobile {
                padding: 0;
                overflow: visible;
                background: none;
                border-radius: 0;
                box-shadow: none;
            }
        }

        &._logs &Content {
            padding: 0;
        }

        &._filter &Content {
            overflow: visible;
        }

        &Head + &Content {
            border-radius: 2rem 2rem 32rem 32rem;

            @include mobile {
                border-radius: 0;
            }
        }
    }

    &__icon {
        display: block;
        object-fit: contain;
    }

    &._walletsCard &__icon,
    &__box._walletsCard &__icon {
        width: 56rem;
        height: 56rem;
        margin-bottom: 3rem;
    }

    &__title {
        position: relative;
        width: 100%;
        margin-bottom: 16rem;

        @include mobile {
            margin-bottom: 24rem;
        }

        &._center {
            align-items: center;
            text-align: center;
        }

        &._notMargin {
            margin-bottom: 0;
        }

        &Icon {
            display: block;
            width: 34rem;
            height: 34rem;
            margin-bottom: 11rem;

            &._important {
                @include icon($colorAlert);
            }

            @include mobile {
                width: 42rem;
                height: 42rem;
            }
        }

        &Support {
            margin-bottom: 8rem;
            color: $colorMain;
            font-weight: 500;
            font-size: 14rem;
            letter-spacing: 3rem;
            text-align: center;
            text-transform: uppercase;
        }

        &Text {
            color: $colorDark;
            font-size: 25.5rem;
            line-height: 1.2;

            @include mobile {
                font-size: 26rem;
                text-align: center;
            }
        }

        &Description {
            margin-top: 8rem;
            font-weight: 300;
            font-size: 15rem;
            line-height: 1.4;

            @include mobile {
                font-size: 16rem;
                line-height: 1.45;
                text-align: center;
            }

            &._notTop {
                @include mobile {
                    margin-top: 0;
                }
            }

            & span,
            & b {
                font-size: 15rem;

                @include mobile {
                    font-size: 16rem;
                }
            }

            & a {
                color: $colorMain;
            }
        }

        &._center &Text,
        &._center &Description {
            text-align: center;
        }
    }

    &__box._center &__title {
        align-items: center;
        text-align: center;
    }

    &__boxHead &__title {
        margin-bottom: 0;
    }

    &__fields {
        width: 100%;

        &:not(._dynamic) {
            flex-wrap: wrap;
        }

        &._dynamic {
            position: relative;

            &._static {
                display: flex;
                flex-wrap: wrap;
            }

            &:not(._static) {
                margin-bottom: -13rem;
            }
        }
    }

    &__field {
        width: 100%;
        height: 50rem;

        @include mobile {
            width: 100%;
            height: 56rem;
            margin-bottom: 8rem;

            & .input {
                background: #fff;
            }

            & .input__support,
            & .input__field {
                justify-content: center;
                text-align: center;
            }

            &._left .input__support,
            &._left .input__field {
                justify-content: flex-start;
                text-align: left;
            }

            & .input__field {
                // color: $colorDark;
            }

            & .input__support {
                color: rgba(#70a6cc, 0.56);
            }
        }

        & + & {
            margin-top: 20rem;

            @include mobile {
                margin-top: 0;
            }
        }
    }

    &__fields._pointContacts {
        flex-wrap: wrap;
    }

    &__fields._pointContacts &__field {
        @include mobile {
            &:not(._area) {
                height: 50rem;
            }

            &._area {
                height: 120rem;
            }
        }

        & .input__field,
        & .input__support {
            // padding-right: 12rem;
            // padding-left: 12rem;
        }

        &._name {
            width: calc(50% - 4rem);
            margin-right: 8rem;
        }

        &._phone {
            width: calc(50% - 4rem);
        }

        &._entrance {
            width: 34%;
            margin-right: 8rem;
        }

        &._floor {
            width: 27%;
            margin-right: 8rem;
        }

        &._room {
            width: calc(100% - 34% - 27% - 16rem);
        }
    }

    &__fields._walletsAccount &__field {
        @include desktop {
            margin-top: 0 !important;

            &._bic {
                width: 35%;
                margin-bottom: 10rem;
            }

            &._account {
                width: calc(100% - 35% - 10rem);
                margin-bottom: 10rem;
                margin-left: auto;
            }

            &._addressatName {
                width: 52%;
                margin-right: 10rem;

                @include mobile {
                    margin-right: 0;
                    margin-bottom: 10rem;
                }
            }

            &._button {
                width: calc(100% - 52% - 10rem);
                margin-left: auto;
            }
        }
    }

    &__fields._walletsCard &__field {
        @include desktop {
            margin-top: 0 !important;

            &._number {
                width: 50%;
                margin-bottom: 10rem;
            }

            &._button {
                width: calc(100% - 50% - 10rem);
            }

            &._exp {
                width: 17%;
                margin-right: 10rem;
            }

            &._cvv {
                width: 15.5%;
                margin-right: 10rem;
            }

            &._info {
                width: calc(100% - 17% - 15.5% - 10rem - 10rem - 10rem);
                margin-left: auto;
            }
        }
    }

    &__fields._dynamic &__field {
        right: 0;
        left: auto;
        z-index: 1;
        width: auto;
        margin-top: 0 !important;

        @include mobile {
            width: 100% !important;
        }

        &._button {
            z-index: 2;
        }
    }

    &__fields._dynamic._static &__field._physical {
        @include desktop {
            margin-bottom: 13rem;

            &._phone,
            &._thirdName {
                width: 35%;
                margin-right: 13rem;
            }

            &._firstName {
                width: 28%;
                margin-right: 13rem;
            }

            &._secondName {
                width: calc(100% - 35% - 28% - 13rem - 13rem);
            }

            &._button {
                width: calc(100% - 35% - 13rem);
            }
        }
    }

    &__fields._dynamic._static &__field._juristic:not(._addWorker) {
        @include desktop {
            margin-bottom: 13rem;

            &._email {
                width: 35%;
                margin-right: 13rem;
            }

            &._thirdName {
                width: 29%;
                margin-right: 13rem;
            }

            &._firstName {
                width: 28%;
                margin-right: 13rem;
            }

            &._inn {
                width: 29%;
                margin-right: 13rem;
            }

            &._secondName {
                width: calc(100% - 35% - 28% - 13rem - 13rem);
            }

            &._button {
                width: calc(100% - 29% - 29% - 13rem - 13rem);
            }
        }
    }

    &__fields._dynamic._static &__field._juristic._addWorker {
        @include desktop {
            margin-bottom: 13rem;

            &._secondName {
                width: calc(100% / 3 - 26rem / 3);
                margin-right: 13rem;
            }

            &._firstName {
                width: calc(100% / 3 - 26rem / 3);
                margin-right: 13rem;
            }

            &._thirdName {
                width: calc(100% / 3 - 26rem / 3);
            }

            &._email {
                width: 45%;
                margin-right: 13rem;
            }

            &._button {
                width: calc(100% - 45% - 13rem);
            }
        }
    }

    &__field + &__button,
    &__fields + &__button {
        margin-top: 17rem;

        @include mobile {
            margin-top: 12rem;
        }
    }

    &__button {
        width: 100%;
        height: 50rem;

        @include mobile {
            height: 56rem;
        }

        &._auto {
            width: auto;
        }

        &._minHeight {
            height: 46rem;

            @include mobile {
                height: 56rem;
            }
        }
    }

    &__link {
        margin-top: 26rem;
        color: $colorMain;
        font-size: 16rem;

        @include mobile {
            margin-top: 24rem;
            font-size: 17rem;
        }

        @include button(0.97);

        @include hover {
            &:hover {
                color: $colorMain2;
            }
        }
    }

    &__blocks {
        width: 100%;
    }

    &__block {
        top: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        transform: translate(0, -50%);

        &:not(._show) {
            &._prev {
                transform: translate(-50rem, -50%);
            }

            &._next {
                transform: translate(50rem, -50%);
            }
        }
    }

    &__tabs {
        width: 100%;
        margin: 24rem 0 -36rem;

        @include mobile {
            justify-content: center;
            width: calc(100% + 64rem);
            margin: 20rem 0 0 -32rem;
            border-bottom: 1px solid rgba(#3e454c, 0.32);
        }
    }

    &__tab {
        display: block;

        & + & {
            margin-left: 20rem;

            @include mobile {
                margin-left: 40rem;
            }
        }

        &Input {
            display: none;
        }

        &View {
            position: relative;
            padding-bottom: 20rem;
            color: $colorDark;
            font-weight: 300;
            font-size: 16rem;
            transition: $trans;

            @include mobile {
                padding-bottom: 16rem;
            }

            @include hover {
                &:hover {
                    color: $colorMain;
                }
            }

            @include button(0.96);

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 3rem;
                background: $colorMain;
                border-radius: 360rem 360rem 0 0;
                opacity: 0;
                transition: $trans;
                content: "";

                @include mobile {
                    bottom: -1rem;
                }
            }
        }

        &Input:checked ~ &View {
            font-weight: 500;

            &::after {
                opacity: 1;
            }
        }
    }

    &__error {
        width: 100%;

        &._parent:not(._empty) {
            margin-top: 20rem;
        }

        &._left * {
            text-align: left;
        }
    }

    &__cancel {
        margin-top: 19rem;
        color: $colorAlert;
        font-size: 16rem;
        transition: $trans;

        @include mobile {
            margin: 20rem auto 0;
            font-size: 18rem;
        }

        @include hover {
            &:hover {
                opacity: 0.64;
            }
        }
    }

    &__calendar {
        width: 100%;
    }

    &__info {
        position: relative;
        width: 100%;
        margin: auto 0;
        padding-left: 13rem;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 2rem;
            height: 100%;
            background: $colorMain;
            border-radius: 0 10rem 10rem 0;
            content: "";
        }

        &Text {
            color: $colorDark;
            font-weight: 300;
            font-size: 14rem;
            line-height: 1.3;

            &._title {
                font-weight: 500;
                font-size: 16rem;
            }
        }
    }

    &__pagination {
        display: flex;
        margin-top: 54rem;

        &Item {
            width: 32rem;
            height: 2rem;
            margin: 0 4rem;
            background: $colorMain;
            transition: $trans;

            &:not(._current) {
                opacity: 0.26;
            }
        }
    }

    &._isDrag {
        transition: none;
    }

    &__mob {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-top: auto;
        border-radius: 24rem 24rem 0 0;
        pointer-events: visible;
        will-change: transform;

        &::before {
            position: absolute;
            bottom: 100%;
            left: 50%;
            z-index: 2;
            width: 56rem;
            height: 3rem;
            margin-bottom: 12rem;
            background: rgba(#000, 0.12);
            border-radius: 360rem;
            transform: translate(-50%, 0);
            transition: $trans;
            content: "";
        }

        &Box {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 26rem 20rem 20rem;
            overflow: hidden;
            overflow-y: auto;
            background: #fff;
            border-radius: 28rem 28rem 0 0;
            transition: $trans;
            will-change: transform;
        }

        &Inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    }

    .body__popup:not(._show) &__mob {
        &::before {
            opacity: 0;
        }
    }
}
