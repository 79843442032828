.publicTopBar {
    width: 100%;
    padding: 58rem 62rem;

    @include mobile {
        height: 100%;
        padding: 21rem 18rem;
    }

    &__inner {
        position: relative;
        justify-content: space-between;
        width: 100%;

        @include mobile {
            align-items: center;
            height: 100%;
        }
    }

    &__logo {
        align-items: center;

        @include button(0.97);

        &Icon {
            display: block;
            height: 34rem;
            margin-right: 9rem;
            transition: $trans;
            pointer-events: visible;

            @include mobile {
                height: 26rem;
                margin-right: 7rem;
                transform-origin: left center;
                will-change: transform;
            }
        }

        &Title {
            display: block;
            height: 30rem;
            margin-top: 4rem;
            transition: $trans;
            pointer-events: visible;

            @include mobile {
                height: 23rem;
                margin-top: 2rem;
            }
        }
    }

    &._scroll &__logo {
        &Icon {
            @include mobile {
                // transform: scale(0.9);
            }
        }

        &Title {
            @include mobile {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &__button {
        align-items: center;
        height: 44rem;
        padding: 6rem 18rem;
        font-size: 14.2rem;
        background: #fdfdfe;
        border-radius: 11rem;
        transition: $trans;
        pointer-events: visible;

        @include mobile {
            justify-content: center;
            width: 44rem;
            height: 44rem;
            padding: 8rem;
            background: #fff;
            border-radius: 12rem;
        }

        &:not(._profile) {
            @include button(0.96);
        }

        &._profile {
            padding: 0;
            background: none;
            border-radius: 0;
        }

        &._show {
            color: $colorMain;
            box-shadow: 0 10rem 32rem rgba(#000, 0.04);

            @include mobile {
                box-shadow: none;
            }
        }

        &._hide {
            opacity: 0;
            pointer-events: none;
        }

        &._auth &Icon {
            @include icon($colorMain);
        }

        &Icon {
            position: relative;
            margin-right: 6rem;

            @include mobile {
                margin-right: 0;
            }

            &Item {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                transition: $trans;
                will-change: transform;

                &:nth-child(1) {
                    transform: translate(0, -6.5rem);

                    @include mobile {
                        transform: translate(0, -7rem);
                    }
                }

                &:nth-child(3) {
                    width: 60%;
                    transform: translate(0, 6.5rem);

                    @include mobile {
                        transform: translate(0, 7rem);
                    }
                }

                &::before {
                    display: block;
                    width: 100%;
                    height: 1.5rem;
                    margin: auto;
                    background: $colorDark;
                    border-radius: 360rem;
                    transition: $trans;
                    content: "";

                    @include mobile {
                        height: 2rem;
                    }
                }
            }
        }

        &._show &IconItem {
            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                width: 100%;
                transform: rotate(-45deg);
            }

            &::before {
                background: $colorMain;
            }
        }

        &._menu &Icon {
            width: 20rem;
            height: 16rem;
            margin-right: 10rem;

            @include mobile {
                width: 19rem;
                height: 19rem;
                margin-right: 0;
            }
        }

        &Counter {
            position: absolute;
            top: -4rem;
            right: -4rem;
            z-index: 2;
            pointer-events: none;
        }
    }

    &._scroll &__button {
        @include mobile {
            box-shadow: 0 2rem 8rem rgba(#000, 0.072);
        }
    }
}
