.animateChange {
    position: relative;
    z-index: 1;
    transition: $trans;

    &._full {
        height: 100% !important;
    }

    &._static {
        position: relative;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 0;
        width: 32rem;
        height: 32rem;
        transform: translate(0, -50%);

        @include loader($colorMain);
    }

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;
        }

        &:not(._show, ._end) {
            transform: translate(0, -10rem);
        }

        &._hide:not(._end) {
            transform: translate(0, 10rem);
        }
    }

    &._center > &__item {
        left: 50%;
        transform: translate(-50%, 0);

        &:not(._show, ._end) {
            transform: translate(-50%, -10rem);
        }

        &._hide:not(._end) {
            transform: translate(-50%, 10rem);
        }
    }

    &._vertical > &__item {
        &:not(._show, ._end) {
            transform: translate(-10rem, 0);
        }

        &._hide:not(._end) {
            transform: translate(10rem, 0);
        }
    }

    &._right &__item {
        right: 0;
        left: auto;
    }

    &._static &__item {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }
}
