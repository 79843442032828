.orderPoint {
    width: 100%;
    padding: 18rem 28rem;

    @include mobile {
        padding: 14rem 18rem;
    }

    &._final {
        padding-top: 12rem;
    }

    &__head {
        position: relative;
        align-items: center;
        width: 100%;
        min-height: 32rem;
        padding-right: 60rem;
        padding-left: 40rem;

        @include mobile {
            padding-right: 60rem;
            padding-left: 36rem;
        }
    }

    &._details &__head {
        @include mobile {
            padding-right: 0;
        }
    }

    &._final &__head {
        min-height: auto;

        @include mobile {
            padding-right: 40rem;
        }
    }

    &__number {
        position: absolute;
        top: 6rem;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 26rem;
        height: 26rem;
        padding-top: 1rem;
        font-size: 16rem;
        background: #e7f5ff;
        border-radius: 8rem;

        @include mobile {
            top: 4rem;
            width: 24rem;
            height: 24rem;
            font-size: 15rem;
            border-radius: 8rem;
        }
    }

    &__edit {
        position: relative;
        display: inline-block;
        padding-left: 15rem;
        color: $colorMain;
        font-size: 14rem;

        @include mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 48rem;
            margin-top: 10rem;
            padding: 0;
            font-size: 16rem;
            background: #f1f9ff;
            border-radius: 12rem;
        }

        @include hover {
            &:hover {
                color: $colorMain2;

                @include icon($colorMain2);
            }
        }

        @include button(0.95);

        &._left {
            margin-left: 12rem;

            @include mobile {
                margin-left: 0;
            }
        }

        &Icon {
            position: absolute;
            bottom: 3rem;
            left: 0;
            width: 9rem;
            height: 9rem;

            @include mobile {
                position: relative;
                bottom: 0;
                width: 14rem;
                height: 14rem;
                margin-right: 6rem;
            }

            @include icon($colorMain);
        }
    }

    &._final &__address {
        margin-bottom: 10rem;
        padding-top: 9rem;

        @include mobile {
            padding-top: 5rem;
        }
    }

    &__address {
        position: relative;
        z-index: 2;
        width: 100%;
        margin-bottom: 8rem;
        padding-top: 9rem;

        @include mobile {
            margin-bottom: 4rem;
            padding-top: 4rem;
        }

        &Inner {
            display: inline;
            margin-right: 12rem;
            font-size: 16rem;
            line-height: 1.3;

            @include mobile {
                margin-right: 0;
                font-size: 18rem;
            }
        }

        &List {
            position: absolute;
            top: 100%;
            z-index: 10;
            width: 100%;
            max-height: 120rem;
            margin-top: 4rem;
            overflow-y: auto;
            background: #fff;
            border: 1rem solid #dde9ef;
            border-radius: 2rem 2rem 10rem 10rem;
            transition: $trans;

            @include mobile {
                width: calc(100% + 64rem);
                max-height: 160rem;
                border-radius: 2rem 2rem 24rem 24rem;
            }

            &Item {
                width: 100%;
                padding: 13rem 16rem;
                font-size: 16rem;
                line-height: 1.3;
                border-bottom: 1rem solid transparent;

                @include mobile {
                    font-size: 18rem;
                }

                &:not(:last-child) {
                    border-bottom-color: #dde9ef;
                }
            }
        }
    }

    &__delete {
        position: absolute;
        top: 10rem;
        right: 32rem;
        z-index: 2;
        width: 16rem;
        height: 16rem;

        @include mobile {
            top: 7rem;
            right: 32rem;
            width: 18rem;
            height: 18rem;
        }

        @include icon(#e1e7ee);
        @include button;

        &:hover {
            @include icon($colorAlert);
        }
    }

    &__drag {
        position: absolute;
        top: 8rem;
        right: 0;
        z-index: 2;
        width: 20rem;
        height: 20rem;

        @include mobile {
            top: 5rem;
            width: 22rem;
            height: 22rem;
        }

        @include hover {
            &:hover {
                @include icon($colorMain);
            }
        }
    }

    &__contacts {
        position: relative;
        z-index: 1;
        display: flex;

        // width: 100%;
        height: 24px;
        padding-left: 41rem;

        @include mobile {
            height: auto;
            padding-left: 38rem;
        }

        &:not(._show) {
            height: 0;
        }

        &Fields {
            position: relative;
            height: 24rem;
            padding-left: 20rem;

            @include mobile {
                flex-wrap: wrap;
                height: auto;
            }

            & + & {
                margin-left: 16rem;
            }

            @include mobile {
                height: 40rem;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 12rem;
                height: 12rem;
                transform: translate(0, -50%);

                @include mobile {
                    width: 16rem;
                    height: 16rem;
                    margin-top: 1rem;
                }
            }
        }

        &Field {
            height: 100%;
            margin-right: 6rem;

            @include mobile {
                margin-right: 0;
                margin-bottom: 8rem;
            }

            &._name {
                width: 130rem;
            }

            &._phone {
                width: 130rem;
            }

            &._entrance {
                width: 82rem;

                @include mobile {
                    width: calc(50% - 4rem);
                    margin-top: 5rem;
                }
            }

            &._floor {
                width: 66rem;

                @include mobile {
                    width: calc(50% - 4rem);
                    margin-top: 5rem;
                    margin-left: auto;
                }
            }

            &._room {
                width: 90rem;
                margin-right: 16rem;

                @include mobile {
                    width: 100%;
                    margin-right: 0;
                }
            }

            &._comment {
                width: 234rem;

                @include mobile {
                    width: 100%;
                    height: 100rem;
                }
            }

            & .input {
                background: #f1f9ff;
                border-radius: 6.5rem;

                @include mobile {
                    border-radius: 10rem;
                }
            }

            & .input__field,
            & .input__support {
                padding: 4rem 6rem;
                font-weight: 300;
                font-size: 13rem;

                @include mobile {
                    padding: 8rem 13rem;
                    font-size: 16rem;
                }
            }

            & .input__field {
                color: $colorDark;
            }
        }

        &Btn {
            color: $colorMain;
            font-size: 16px;
            border-bottom: 1rem solid rgba($colorMain, 0.32);
        }
    }

    &__info {
        width: 100%;
        padding-left: 41rem;

        @include mobile {
            padding-left: 0;
        }

        &Block {
            width: 100%;

            @include mobile {
                padding-left: 37rem;
            }

            & + & {
                margin-top: 9rem;

                @include mobile {
                    margin-top: 10rem;
                }
            }
        }

        &Icon {
            width: 10rem;
            height: 10rem;
            margin-right: 6rem;

            @include mobile {
                width: 16rem;
                height: 16rem;
                margin-top: 1rem;
                margin-right: 8rem;
            }

            &._contacts {
                transform: translate(0, 1rem) scale(1.1);

                @include mobile {
                    margin-right: 8rem;
                    margin-left: 0;
                    transform: none;
                }
            }
        }

        &Item {
            font-weight: 300;
            font-size: 13rem;

            @include mobile {
                font-size: 14rem;
                line-height: 1.4;
            }

            & span {
                display: inline-block;
                margin: 0 6rem;
                color: $colorMain;
                font-weight: 300;
                font-size: 13rem;

                @include mobile {
                    font-size: 14rem;
                    line-height: 1.4;
                }
            }
        }
    }
}
