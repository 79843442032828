.profileUsers {
    width: 100%;

    &__items {
        width: 100%;

        &._static {
            display: flex;
            flex-direction: column;
        }
    }

    &._loading &__items {
        opacity: 0;
    }

    &__items._static &__item:not(._last) {
        margin-bottom: 10rem;
    }

    &__item {
        width: 100%;
    }

    &__item:not(._show) &__user {
        opacity: 0;
    }

    &__user {
        width: 100%;
        padding: 24rem 28rem;
        background: #f2f7fa;
        border-radius: 13rem;
        transition: $trans;

        @include mobile {
            padding: 22rem 20rem 20rem;
            border: 3rem solid #fff;
            border-radius: 24rem;
            box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);
        }

        &Inner {
            position: relative;
            align-items: stretch;
            width: 100%;

            @include mobile {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &Block {
            padding: 4rem 0;

            @include mobile {
                padding: 3rem 0;
            }

            &._status {
                width: 17%;
                margin-right: 20rem;
                padding-right: 32rem;
                border-right: 1rem solid rgba(#c8d1dd, 0.6);

                @include mobile {
                    order: 3;
                    width: 100%;
                    margin-right: 0;
                    padding-right: 0;
                    border: 0;
                }
            }

            &._name {
                width: 30%;
                margin-right: 20rem;
                padding-right: 32rem;
                border-right: 1rem solid rgba(#c8d1dd, 0.6);

                @include mobile {
                    width: 100%;
                    margin-right: 0;
                    padding-right: 0;
                    border: 0;
                }
            }

            &._email {
                width: 30%;

                @include mobile {
                    width: 100%;
                }
            }
        }

        &Text {
            font-weight: 300;
            font-size: 16rem;
            line-height: 1.3;

            @include mobile {
                font-size: 18rem;
            }

            &._online {
                color: $colorSuccess;
            }

            &._offline {
                color: #9da8b4;
            }

            &._notActive {
                color: #9da8b4;
            }
        }

        &Block._name &Text {
            @include mobile {
                font-weight: 400;
            }
        }

        &Block._status &Text {
            position: relative;
            padding-left: 14rem;
            font-weight: 400;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                font-weight: 400;
                font-size: 27rem;
                transform: translate(0, -50%);
                content: "•";
            }
        }

        &Actions {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
        }

        &Action {
            width: 16rem;
            height: 16rem;

            @include icon(rgba($colorGrey, 0.5));
        }

        &Delete {
            align-items: center;
            justify-content: center;
            order: 4;
            width: 100%;
            height: 46rem;
            margin-top: 20rem;
            padding: 12rem 20rem;
            color: $colorAlert;
            font-weight: 500;
            font-size: 18rem;
            text-align: center;
            background: $colorAlertBack;
            border-radius: 12rem;

            @include button(0.96);

            &Icon {
                width: 16rem;
                height: 16rem;
                margin-left: 8rem;

                @include icon($colorAlert);
            }
        }
    }
}
