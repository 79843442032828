.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: contain;

    & svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    & ellipse,
    & path,
    & circle,
    & line,
    & polyline,
    & rect {
        transition: $trans;
    }
}
