.publicTariffs {
    position: relative;
    width: 100%;

    &__loader {
        @include loader($colorMain);
    }

    &__blocks {
        position: relative;
        width: 100%;
    }

    &:not(._ready) &__blockStep,
    &:not(._ready) &__blockButton {
        opacity: 0;
    }

    &__block {
        align-items: center;
        width: 100%;
        padding: 56rem 0 50rem;
        will-change: transform;

        @include mobile {
            min-height: calc(var(--wheight) - 116rem - 32rem);
            padding: 0;
        }

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-50rem, 0);
            }

            &._next {
                transform: translate(50rem, 0);
            }
        }

        &Back {
            position: absolute;
            top: 72rem;
            left: -93rem;
            padding-left: 24rem;
            color: $colorMain;
            font-weight: 500;
            font-size: 16rem;

            @include mobile {
                position: relative;
                top: 0;
                left: 0;
                margin-bottom: 16rem;
                margin-left: -12rem;
                font-size: 18rem;
            }

            @include button(0.96);

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 18rem;
                height: 18rem;
                transform: translate(0, -50%);

                @include icon($colorMain);
            }
        }

        &Head {
            align-items: center;
            width: 100%;
            margin-bottom: 26rem;

            @include mobile {
                margin-bottom: 26rem;
            }

            &Title {
                margin-bottom: 8rem;
                font-weight: 500;
                font-size: 40rem;
                line-height: 1.2;
                text-align: center;

                @include mobile {
                    font-size: 28rem;
                }
            }

            &Description {
                font-weight: 300;
                font-size: 16rem;
                line-height: 1.5;
                text-align: center;

                & span {
                    display: inline-block;
                    white-space: nowrap;
                }

                &Counter {
                    font-weight: 300;
                    font-size: 16rem;
                    line-height: 0.55;
                }
            }
        }

        &Step {
            position: relative;
            width: 100%;
            padding: 66rem 86rem;
            background: rgba(#fff, 0.64);
            border-radius: 32rem;
            transition: $trans;

            @include mobile {
                padding: 0;
                background: none;
                border-radius: 0;
            }

            & + & {
                margin-top: 22rem;

                @include mobile {
                    margin-top: 28rem;
                }
            }

            &Done {
                position: absolute;
                top: 24rem;
                right: 24rem;
                z-index: 2;
                width: 20rem;
                height: 20rem;

                @include mobile {
                    top: 0;
                    right: auto;
                    left: 44rem;
                    width: 20rem;
                    height: 20rem;
                }
            }

            &Head {
                width: 100%;
                margin-bottom: 24rem;

                @include mobile {
                    padding-left: 12rem;
                }
            }

            &SubTitle {
                margin-bottom: 10rem;
                color: $colorMain;
                font-weight: 500;
                font-size: 24rem;

                @include mobile {
                    font-size: 22rem;
                }
            }

            &Title {
                color: $colorDark;
                font-weight: 500;
                font-size: 24rem;

                @include mobile {
                    font-size: 18rem;
                }

                & span {
                    display: inline-block;
                    white-space: nowrap;
                }

                &Counter {
                    font-weight: 500;
                    font-size: 24rem;
                    line-height: 0.55;

                    @include mobile {
                        font-size: 18rem;
                    }
                }
            }
        }

        &Button {
            margin: 40rem auto 0;
            transition: $trans;

            @include mobile {
                width: 100%;
                margin: auto auto 0;
                padding-top: 40rem;
            }
        }
    }
}
