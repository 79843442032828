.orderMarkets {
    width: 100%;

    &__loader {
        @include loader($colorMain);

        height: 100%;
    }

    &__content {
        width: 100%;
    }

    &__items {
        width: calc(100% + 12rem);
        margin-left: -6rem;

        @include mobile {
            width: calc(100% + 6rem);
            margin-left: -3rem;
        }

        &._static {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
        }
    }

    &__item {
        width: calc(100% / 3);
        padding: 0 6rem;

        @include mobile {
            width: 50%;
            padding: 0 3rem;
        }

        &._out {
            width: calc(100% / 4);

            @include mobile {
                width: 50%;
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;

            @include hover {
                &:hover {
                    transform: translate(0, -4rem);
                }
            }
        }
    }

    &__items._static &__item {
        margin-bottom: 12rem;

        @include mobile {
            margin-bottom: 6rem;
        }
    }

    &__item:not(._show) &__market {
        opacity: 0;
    }

    &._out &__market {
        &Head,
        &Content {
            box-shadow: 0 2rem 8rem rgba(#000, 0.032);
        }
    }

    &__market {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        border: 1px solid transparent;
        border-radius: 16rem;
        transition: $trans;

        @include mobile {
            border-radius: 20rem;
        }

        @include button(0.98);

        &._disabled {
            opacity: 0.64;
        }

        &._current {
            border-color: $colorMain;
        }

        &Head,
        &Content {
            width: 100%;
            padding: 23rem 30rem;
            background: #fff;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);

            @include mobile {
                padding: 12rem 18rem;
            }
        }

        &._out &Head,
        &._out &Content {
            padding: 16rem 24rem;

            @include mobile {
                padding: 14rem 22rem;
            }
        }

        &Head {
            display: flex;
            align-items: center;
            height: 90rem;
            margin-bottom: 1rem;
            border-radius: 16rem 16rem 2rem 2rem;

            @include mobile {
                height: 64rem;
                border-radius: 18rem 18rem 2rem 2rem;
            }
        }

        &._out &Head {
            height: 75rem;

            @include mobile {
                height: 64rem;
            }
        }

        &Content {
            position: relative;
            align-items: center;
            height: calc(100% - 91rem);
            min-height: 60rem;
            padding-top: 14rem;
            padding-bottom: 14rem;
            border-radius: 2rem 2rem 16rem 16rem;

            @include mobile {
                height: calc(100% - 64rem - 1rem);
                min-height: 60rem;
                padding-top: 10rem;
                padding-bottom: 10rem;
                border-radius: 2rem 2rem 18rem 18rem;
            }
        }

        &._out &Content {
            height: calc(100% - 75rem - 1rem);

            @include mobile {
                height: calc(100% - 64rem - 1rem);
            }
        }

        &Logo {
            width: 100%;
            height: 36rem;
            object-fit: contain;
            object-position: left center;
            pointer-events: none;

            @include mobile {
                height: 100%;
            }
        }

        &._out &Logo {
            height: 34rem;

            @include mobile {
                height: 100%;
            }
        }

        &Loader {
            position: absolute;
            top: 50%;
            left: 32rem;
            z-index: 2;
            align-items: center;
            color: $colorGrey;
            font-size: 21rem;
            transform: translate(0, -50%);

            @include mobile {
                left: 18rem;
                font-size: 18rem;
            }

            &Item {
                width: 20rem;
                height: 20rem;
                margin-right: 5rem;

                @include mobile {
                    width: 20rem;
                    height: 20rem;
                    margin-right: 6rem;
                }

                @include loader($colorGrey);
            }
        }

        &Info {
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        &Price {
            font-size: 25rem;
            transition: $trans;

            @include mobile {
                font-size: 24rem;
            }

            &:not(._disabled) {
                display: flex;
                align-items: center;
                height: 36rem;
                white-space: nowrap;
            }

            &._disabled {
                position: relative;
                padding-left: 12rem;
                color: $colorDark;
                font-weight: 300;
                font-size: 15.5rem;
                line-height: 1.4;

                @include mobile {
                    padding-left: 18rem;
                    padding-left: 0;
                    font-size: 14rem;
                }

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1rem;
                    height: 34rem;
                    background: $colorDark;
                    transform: translate(0, -50%);
                    content: "";

                    @include mobile {
                        display: none;
                        height: 48rem;
                    }
                }
            }
        }

        &._current &Price {
            color: $colorMain;
        }

        &._process &Price {
            opacity: 0;
        }
    }
}
