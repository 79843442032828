.authButton {
    position: relative;
    width: 100%;
    height: 100%;

    &__inner {
        position: relative;
        z-index: 2;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 6rem 18rem;
        font-size: 14.2rem;
        background: #fdfdfe;
        border-radius: 11rem;

        @include mobile {
            padding: 18rem 26rem;
            border-radius: 16rem;
        }

        &::before {
            border-radius: 11rem;
        }

        @include button(0.96);

        &::after {
            position: absolute;
            top: 50%;
            right: 16rem;
            border: 2rem solid transparent;
            border-right-color: $colorMain;
            border-bottom-color: $colorMain;
            transform: translate(0, -50%) rotate(45deg);
            opacity: 0;
            transition: $trans;
            content: "";

            @include mobile {
                display: none;
            }
        }
    }

    &._profile &__inner {
        background: #fff;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);
    }

    &._showList &__inner {
        background: #fff;
        border-radius: 11rem 11rem 2rem 2rem;

        &::before {
            border-radius: 11rem 11rem 2rem 2rem;
        }

        &::after {
            margin-top: 2rem;
            transform: translate(0, -50%) rotate(-135deg);
        }
    }

    &._auth &__inner {
        padding-right: 32rem;

        &::after {
            opacity: 1;
        }
    }

    &__icon {
        width: 16rem;
        height: 16rem;
        margin-right: 6rem;

        @include icon(#9da8b4);

        @include mobile {
            width: 18rem;
            height: 18rem;
            margin-right: 6rem;
        }
    }

    &._auth &__icon {
        @include icon($colorMain);
    }

    &__content {
        font-size: 14.2rem;
        white-space: nowrap;
        transition: $trans;

        @include mobile {
            font-size: 18rem;
        }
    }

    @include hover {
        &__inner:hover &__icon {
            @include icon($colorMain);
        }

        &__inner:hover &__content {
            color: $colorMain;
        }
    }

    &._profile &__listItem {
        background: #fff;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        width: 100%;
        margin-top: 1rem;

        &:not(._show) {
            // transform: translate(0, 4rem);
        }

        &Item {
            position: relative;
            width: 100%;
            padding: 13rem 22rem 13rem 41rem;
            background: #fafbfb;
            border-radius: 2rem;

            @include hover {
                &:not(._logout):hover {
                    background: #eff9ff;

                    @include icon($colorMain);
                }

                &._logout:hover {
                    background: $colorAlertBack;
                }
            }

            @include button;

            &._order {
                background: #eff9ff !important;
            }

            &:last-child {
                border-radius: 2rem 2rem 11rem 11rem;
            }

            & + & {
                margin-top: 1rem;
            }

            &Content {
                position: relative;
                font-weight: 300;
                font-size: 14rem;
                white-space: nowrap;
                transition: $trans;
            }

            &._order &Content {
                color: $colorMain;
                font-weight: 400;
            }

            &._logout &Content {
                color: $colorAlert;
            }

            @include hover {
                &:not(._logout):hover &Content {
                    color: $colorMain;
                }
            }

            &Loader {
                position: absolute;
                top: 50%;
                left: 17rem;
                z-index: 3;
                width: 18rem;
                height: 18rem;
                transform: translate(0, -50%);

                @include loader($colorMain);
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 19rem;
                width: 14rem;
                height: 14rem;
                transform: translate(0, -50%);

                @include icon(#9da8b4);
            }

            &._order &Icon {
                @include icon($colorMain);
            }

            &._settings &Icon,
            &._wallets &Icon {
                left: 18rem;
                width: 15rem;
                height: 15rem;
            }

            &._chats &Icon {
                // left: 18rem;
                // width: 15rem;
                // height: 15rem;
            }

            &._logout &Icon {
                left: 20rem;

                @include icon($colorAlert);
            }

            &Counter {
                position: absolute;
                bottom: 100%;
                left: 100%;
                z-index: 2;
                margin: 0 0 -4rem;
            }
        }
    }
}
