﻿@font-face {
    font-weight: 300;
    font-family: Circe;
    font-style: normal;
    src: local('Circe'), url('../fonts/Circe/Circe-Light.woff');
}

@font-face {
    font-weight: 400;
    font-family: Circe;
    font-style: normal;
    src: local('Circe'), url('../fonts/Circe/Circe-Regular.woff');
}

@font-face {
    font-weight: 600;
    font-family: Circe;
    font-style: normal;
    src: local('Circe'), url('../fonts/Circe/Circe-Bold.woff');
}