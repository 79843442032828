.filter {
    width: 100%;

    &__block {
        width: 100%;

        & + & {
            margin-top: 26rem;

            @include mobile {
                margin-top: 28rem;
            }
        }

        &Head {
            position: relative;
            width: 100%;
            margin-bottom: 12rem;
            font-weight: 300;
            font-size: 14rem;

            @include mobile {
                margin-bottom: 14rem;
                font-size: 16rem;
            }

            &Delete {
                position: absolute;
                top: 50%;
                right: 0;
                z-index: 2;
                width: 16rem;
                height: 16rem;
                transform: translate(0, -50%);

                @include mobile {
                    width: 20rem;
                    height: 20rem;
                }

                @include icon(#e1e7ee);
                @include button(0.9, true);

                &::before {
                    top: 50%;
                    left: 50%;
                    width: 30rem;
                    height: 30rem;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                }

                @include hover {
                    &:hover {
                        @include icon($colorAlert);
                    }
                }
            }
        }

        &Content {
            width: 100%;
        }

        &Input {
            width: 100%;
        }

        &Items {
            flex-wrap: wrap;
            width: calc(100% + 6rem);
            margin-bottom: -6rem;
        }

        &Item {
            margin: 0 6rem 6rem 0;
            padding: 8rem 16rem;
            font-weight: 300;
            font-size: 15rem;
            background: #f2f7fa;
            border-radius: 360rem;

            @include mobile {
                padding: 8rem 14rem;
                font-size: 16rem;
            }

            @include hover {
                &:hover {
                    color: #fff;
                    background: $colorMain2;
                }
            }

            @include button(0.97);

            &._current {
                color: #fff;
                font-weight: 500;
                background: $colorMain;
            }
        }
    }

    &__button {
        width: 100%;

        &._parent {
            position: sticky;
            bottom: -50rem;
            z-index: 10;
            width: calc(100% + 80rem);
            margin-left: -40rem;
            border-radius: 0 0 32rem 32rem;
            transition: $trans;

            &:not(._empty) {
                margin-bottom: -40rem;
                background: linear-gradient(to top, #fff, rgba(#fff, 0));
            }
        }

        &._parent:not(._empty) &._child {
            padding-top: 20rem;
            padding-bottom: 20rem;
        }

        &._parent &._child {
            padding-right: 40rem;
            padding-left: 40rem;
        }

        &Inner {
            width: 100%;
            height: 50rem;

            @include mobile {
                height: 56rem;
            }
        }
    }
}
