.profileOrderPreview {
    width: 100%;
    height: 100%;

    &__content {
        position: relative;
        align-items: stretch;
        width: 100%;
        margin-bottom: 1rem;
        padding: 8rem;
        background: #fff;
        border-radius: 20rem 20rem 2rem 2rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            padding: 12rem;
            border-radius: 24rem;
        }
    }

    &__top {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        @include mobile {
            right: 0;
            left: auto;
        }
    }

    &__status {
        z-index: 2;
        padding: 5rem 14rem;
        color: #fff;
        font-weight: 600;
        font-size: 13rem;
        background: $colorMain;
        border-radius: 18rem 0 12rem;
        transition: $trans;

        @include mobile {
            padding: 7rem 22rem 7rem 18rem;
            font-weight: 500;
            font-size: 16rem;
            border-radius: 0 24rem 0 22rem;
        }

        &Inner {
            display: inline-block;
            color: #fff;
            font-weight: 600;
            font-size: 13rem;
            line-height: 0.5;
            white-space: nowrap;

            @include mobile {
                font-weight: 500;
                font-size: 16rem;
            }

            &._parent {
                margin-left: 2rem;
            }
        }
    }

    &__blocks {
        align-items: stretch;
        padding: 32rem 0 22rem 28rem;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding: 8rem 16rem 12rem;
        }
    }

    &__block {
        @include mobile {
            position: relative;
            width: 100%;
            padding-top: 18rem;
            padding-left: 32rem !important;
        }

        &._location {
            max-width: 130rem;
        }

        &._holder {
            width: 190rem;

            @include mobile {
                width: 100%;
            }
        }

        & + & {
            margin-left: 25rem;
            padding-left: 25rem;
            border-left: 1rem solid #e1e7ee;

            @include mobile {
                margin-top: 16rem;
                margin-left: 0;
                padding-left: 0;
                border-top: 1rem solid #e1e7ee;
                border-left: 0;
            }
        }

        &Icon {
            width: 22rem;
            height: 22rem;
            margin-bottom: 8rem;

            @include icon(#c6dbe6);

            @include mobile {
                position: absolute;
                top: 17rem;
                left: 0;
                width: 20rem;
                height: 20rem;
                margin-bottom: 0;
            }
        }

        &Value {
            min-height: 25rem;
            margin-top: -3rem;
            margin-bottom: 2rem;
            margin-left: -1rem;
            color: $colorDark;
            font-size: 25rem;
            line-height: 1.3;

            @include mobile {
                margin: 0;
                margin-top: -1rem;
                font-size: 18rem;
            }
        }

        &._market &Value {
            display: flex;
            align-items: flex-end;
            height: 28rem;
            margin-top: 0;

            @include mobile {
                display: block;
                height: 25rem;
                height: auto;
            }
        }

        &._holder &Value {
            position: relative;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &Description {
            font-weight: 300;
            font-size: 13rem;

            @include mobile {
                display: none;
            }
        }

        &Market {
            width: 132rem;
            height: 34rem;
            margin-top: -1rem;
            object-fit: contain;
            object-position: left center;

            @include mobile {
                width: 100%;
                height: 36rem;
                margin-top: -8rem;
            }
        }

        &Holder {
            position: relative;
            padding-left: 40rem;

            &Avatar {
                position: absolute;
                top: 50%;
                left: 0;
                width: 32rem;
                height: 32rem;
                transform: translate(0, -50%);
            }

            &Name {
                color: $colorDark;
                font-size: 25rem;
            }
        }
    }

    &._draft &__more {
        @include mobile {
            flex-direction: row-reverse;
        }

        &Support {
            @include mobile {
                margin: 0 10rem 0 0;
            }
        }
    }

    &__more {
        align-items: center;
        justify-content: center;
        width: 104rem;
        margin-left: auto;
        padding: 20rem 14rem;
        background: #f0f9ff;
        border-radius: 13rem;
        transition: $trans;

        @include mobile {
            flex-direction: row;
            width: 100%;
            height: 56rem;
            margin: 0;
            padding: 18rem 12rem;
            border-radius: 18rem;
        }

        @include button(0.97);

        &Icon {
            width: 20rem;
            height: 20rem;
            margin-bottom: 6rem;
            transition: $trans;
            will-change: transform;

            @include mobile {
                width: 20rem;
                height: 20rem;
                margin-bottom: 0;
            }
        }

        &Support {
            margin-bottom: 4rem;
            color: $colorMain;
            font-size: 14rem;
            transition: $trans;

            @include mobile {
                margin: 0 0 0 10rem;
                font-size: 18rem;
            }
        }

        &Description {
            font-weight: 300;
            font-size: 10rem;
            line-height: 1.3;
            text-align: center;
            transition: $trans;

            @include mobile {
                display: none;
            }
        }
    }

    &__foot {
        position: relative;
        align-items: stretch;
        width: 100%;
        padding: 8rem;
        background: #fff;
        border-radius: 2rem 2rem 20rem 20rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);
    }

    &__addresses {
        width: calc(100% - 104rem);
        padding: 11rem 20rem 10rem 28rem;

        @include mobile {
            position: relative;
            flex-direction: column;
            width: 100%;
            margin-bottom: 12rem;
            padding: 22rem 16rem 22rem 48rem;
            background: #f8fafc;
            border-radius: 18rem;
        }

        &Support {
            position: relative;
            width: 130rem;
            padding-left: 20rem;
            font-size: 13rem;

            @include mobile {
                position: static;
                width: 100%;
                padding-left: 0;
                font-size: 18rem;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 12rem;
                height: 12rem;
                transform: translate(0, -50%);

                @include mobile {
                    top: 22rem;
                    left: 17rem;
                    width: 20rem;
                    height: 20rem;
                    transform: translate(0, 0);
                }
            }
        }

        &Value {
            width: calc(100% - 130rem);
            margin-top: -2rem;
            padding-left: 2rem;
            font-weight: 300;
            font-size: 13rem;
            line-height: 1.3;

            @include mobile {
                display: block;
                width: 100%;
                margin-top: 6rem;
                padding-left: 0;
                font-size: 15rem;
            }

            & span {
                display: inline-block;
                margin: 0 6rem;
                color: $colorMain;
                font-weight: 600;
                transform: scale(0.8) translate(0, 1rem);
                transition: $trans;

                @include mobile {
                    transform: scale(1.2);
                }
            }
        }
    }

    @each $status, $color in $orderStatuses {
        &._#{$status} &__status {
            background: $color;
        }

        &._#{$status} &__more {
            background: rgba($color, 0.1);

            &:focus-visible {
                box-shadow: 0 0 0 3rem rgba($color, 0.24);
            }

            &Icon {
                @include icon($color);
            }

            &Support {
                color: $color;
            }

            @include hover {
                &:hover {
                    background: rgba($color, 0.2);
                }

                &:hover i {
                    transform: scale(1.1);
                }
            }
        }

        &._#{$status} &__addresses {
            &SupportIcon {
                @include icon($color);
            }

            &Value span {
                color: $color;
            }
        }

        &._#{$status} &__repeat {
            color: $color;
            background: rgba($color, 0.1);

            &:focus-visible {
                box-shadow: 0 0 0 3rem rgba($color, 0.24);
            }

            @include hover {
                &:hover {
                    background: rgba($color, 0.2);
                }

                &:hover i {
                    transform: scale(1.1);
                }
            }

            &Icon {
                @include icon($color);
            }
        }
    }

    &__repeat {
        align-items: center;
        justify-content: center;
        width: 104rem;
        margin-left: auto;
        padding: 11rem 6rem 10rem;
        color: $colorMain;
        font-size: 12rem;
        background: #f0f9ff;
        border-radius: 13rem;
        transition: $trans;

        @include mobile {
            flex-direction: row-reverse;
            width: 100%;
            height: 56rem;
            margin-top: 8rem;
            padding: 18rem 16rem;
            font-size: 18rem;
            border-radius: 18rem;
        }

        @include button(0.97);

        &._delete {
            color: $colorAlert !important;
            background: #ffede9 !important;

            &:focus-visible {
                box-shadow: 0 0 0 3rem rgba($colorAlert, 0.24) !important;
            }

            @include hover {
                &:hover {
                    background: rgba($colorAlert, 0.2) !important;
                }
            }
        }

        &Icon {
            width: 14rem;
            height: 14rem;
            margin-right: 4rem;
            transition: $trans;
            will-change: transform;

            @include mobile {
                width: 22rem;
                height: 22rem;
                margin-right: 0;
                margin-left: 8rem;
            }
        }

        &._delete &Icon {
            @include icon($colorAlert);

            width: 12rem;
            height: 12rem;
            margin-top: -1rem;

            @include mobile {
                width: 18rem;
                height: 18rem;
            }
        }
    }
}
