.button {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 13rem 26rem;
    color: #fff;
    font-size: 16rem;
    line-height: 1.2;
    background: $colorMain;
    border-radius: 12rem;
    box-shadow: 0 9rem 10rem rgba($colorMain, 0.32);

    @include mobile {
        padding: 17rem 20rem 18rem;
        font-size: 17rem;
        border-radius: 16rem;
        box-shadow: 0 12rem 16rem rgba($colorMain, 0.32);
    }

    @include hover {
        &:not(._disabled):hover {
            background: $colorMain2;
        }
    }

    @include button(0.97);

    &._minHorPaddings {
        padding-right: 20rem;
        padding-left: 20rem;
    }

    &._white {
        color: $colorDark;
        font-weight: 300;
        background: rgba(#fff, 0.74);
        box-shadow: none;

        @include mobile {
            background: #fff;
        }

        @include hover {
            &:not(._disabled):hover {
                background: #fff;
            }
        }

        &._alert {
            color: $colorAlert;
            font-weight: 400;
        }
    }

    &._blue {
        color: $colorMain;
        background: $colorMainBack;
        box-shadow: none;

        @include icon($colorMain);
        @include loader($colorMain);

        @include hover {
            &:not(._disabled):hover {
                background: $colorMainBack2;
            }
        }
    }

    &._alertLight {
        color: $colorAlert;
        background: $colorAlertBack;
        box-shadow: none;

        @include icon($colorAlert);
        @include loader($colorAlert);

        @include hover {
            &:not(._disabled):hover {
                background: $colorAlertBack2;
            }
        }
    }

    &._disabled {
        opacity: 0.32;
    }

    & i {
        display: block;
        width: 16rem;
        height: 16rem;
        object-fit: contain;

        @include mobile {
            width: 18rem;
            height: 18rem;
        }

        &._next {
            margin-left: 8rem;

            @include mobile {
                width: 20rem;
                height: 20rem;
                margin-left: 10rem;
            }
        }

        &._addOrderPrev {
            width: 14rem;
            height: 14rem;
            margin-right: 8rem;
        }

        &._logoutPrev {
            width: 16rem;
            height: 16rem;
            margin-right: 6rem;
        }

        &._arrowPrev {
            margin-right: 8px;
            margin-left: -4rem;
            transform: translate(0, 1rem) scale(0.9);
        }

        &._arrowNext {
            margin-right: -4rem;
            margin-left: 8rem;
            transform: translate(0, 1rem) scale(0.9);

            @include mobile {
                margin-left: 8rem;
            }
        }

        &._add {
            margin-left: 7rem;
        }

        &._logout {
            @include mobile {
                width: 20rem;
                height: 20rem;
                margin-left: 8rem;
            }
        }
    }

    &._white i {
        @include icon($colorMain);
    }

    &._alert i {
        @include icon($colorAlert);
    }

    &__loader {
        background: inherit;
        border-radius: inherit;

        & ._LOADERITEM {
            width: 26rem;
            height: 26rem;

            @include mobile {
                width: 26rem;
                height: 26rem;
            }
        }
    }

    &._white &__loader {
        @include loader($colorMain);
    }
}
