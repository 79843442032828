.profileChatsWindow {
    width: 100%;
    height: 100%;

    &__head {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 74rem;
        margin-bottom: 2rem;
        padding: 14rem 24rem;
        overflow: hidden;
        background: #fff;
        border-radius: 20rem 20rem 2rem 2rem;
        box-shadow: 0 4rem 8rem rgba(#000, 0.035);

        @include mobile {
            height: 64rem;
            margin-bottom: 1rem;
            padding: 12rem 16rem;
        }

        &Inner {
            align-items: center;
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        &Block {
            &._actions {
                margin-left: auto;
            }
        }

        &Info {
            position: relative;
            padding-left: 54rem;

            @include mobile {
                padding-left: 50rem;
            }

            &Avatar {
                position: absolute;
                top: 50%;
                left: 0;
                width: 44rem;
                height: 44rem;
                transform: translate(0, -50%);

                @include mobile {
                    width: 40rem;
                    height: 40rem;
                }
            }

            &Title {
                margin-bottom: 6rem;
                font-weight: 500;
                font-size: 18rem;
            }

            &Description {
                font-weight: 300;
                font-size: 14rem;
            }
        }

        &Actions {
            align-items: center;
        }

        &Action {
            width: 36rem;
            height: 36rem;
            background: #f2f7fa;
            border-radius: 11rem;

            @include button(0.94);

            &._close {
                background: $colorMain;

                // @include icon($colorMain);
            }

            &Wrap {
                width: 36rem;
                margin-left: 6rem;
                transition: $trans;

                &:not(._show) {
                    width: 0;
                    margin-left: 0;
                }
            }

            &Icon {
                width: 100%;
                height: 100%;
            }

            &Loader {
                background: inherit;
                border-radius: inherit;

                @include loader($colorMain);

                & ._LOADERITEM {
                    width: 20rem;
                    height: 20rem;
                }
            }

            &._close &Loader {
                @include loader(#fff);
            }
        }
    }

    &:not(._ready) &__headInner {
        opacity: 0;
    }

    &__content {
        position: relative;
        z-index: 2;
        width: 100%;
        max-height: calc(100% - 74rem - 84rem - 4rem);
        margin: auto 0;
        background: #fff;
        border-radius: 2rem;
        box-shadow: 0 4rem 8rem rgba(#000, 0.035);
        transition: $trans;

        @include mobile {
            max-height: calc(100% - 64rem - 69rem - 2rem);
            overflow: hidden;
        }

        &Loader {
            @include loader($colorMain);
        }

        &ScrollLoader {
            @include loader($colorMain);
        }

        &Scroll {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            max-height: 100%;
            overflow-y: auto;
            transition: $trans;

            &:not(._ready) {
                opacity: 0;
            }
        }

        &Messages {
            width: 100%;
            padding: 20rem 0;
        }

        &Message {
            display: flex;
            align-items: flex-end;
            width: 100%;
            padding: 0 24rem;
            transition: $trans;

            @include mobile {
                padding: 0 12rem;
            }

            &._start,
            &._end {
                padding: 0;

                &:not(._hide, ._last) {
                    margin-bottom: 16rem;
                }
            }

            &._end:not(._hide) {
                margin-top: 16rem;
            }

            &._end + &._start:not(._hide) {
                margin-top: -8rem;
            }

            &._date {
                padding: 0;

                &:not(._hide) {
                    margin-top: 16rem;
                }

                &:not(._hide, ._last) {
                    margin-bottom: 16rem;
                }
            }

            &._hide {
                height: 0;
                opacity: 0;
            }

            &Inner {
                width: 100%;
            }
        }

        &Ticket {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 10rem;
            background: #f3f7f9;

            &Text {
                color: $colorGrey;
                font-weight: 300;
                font-size: 13rem;

                & b {
                    color: $colorGrey;
                    font-weight: 500;
                }
            }
        }

        &Date {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            &Text {
                padding: 6rem 8rem;
                color: $colorGrey;
                font-weight: 400;
                font-size: 14rem;
                background: #f3f7f9;
                border-radius: 7rem;
            }
        }
    }

    &__emodzi {
        position: absolute;
        bottom: 100%;
        left: 50%;
        z-index: 2;
        width: 250rem;
        margin-bottom: 8rem;
        transform: translate(-50%, 0);

        &:not(._show) {
            transform: translate(-50%, -6rem);

            @include mobile {
                transform: translate(0, 100%);
            }
        }

        @include mobile {
            bottom: 0;
            left: 0;
            width: 100%;
            margin-bottom: 0;
            background: #fff;
            border-radius: 20rem 20rem 0 0;
            box-shadow: 0 -4rem 8rem rgba(#000, 0.05);
            transform: translate(0, 0);
        }
    }

    &__foot {
        position: relative;
        z-index: 3;
        width: 100%;
        margin-top: 2rem;
        padding: 22rem 24rem;
        background: #fff;
        border-radius: 2rem 2rem 20rem 20rem;
        box-shadow: 0 4rem 8rem rgba(#000, 0.035);

        @include mobile {
            margin-top: 1rem;
            padding: 14rem 16rem;
            border-radius: 2rem 2rem 0 0;
        }

        &Files {
            width: 100%;

            &._static {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &File {
            width: 150rem;
            padding: 8rem;
            background: #f1f5f7;
            border-radius: 12rem;

            @include mobile {
                width: calc(50% - 8rem);
            }

            &Delete {
                position: absolute;
                top: -8rem;
                right: -8rem;
                z-index: 2;
                width: 20rem;
                height: 20rem;
                background: #fff;
                border-radius: 6rem;
                box-shadow: 0 0 2rem rgba($colorDark, 0.2);

                @include mobile {
                    width: 24rem;
                    height: 24rem;
                    border-radius: 7rem;
                }

                @include button(0.93);

                &Icon {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &Files._static &File {
            margin: 0 8rem 8rem 0;
        }

        &Inner {
            align-items: flex-end;
            width: 100%;
            transition: $trans;
        }

        &._disabled &Inner {
            opacity: 0.64;
            pointer-events: none;
        }

        &Input {
            flex: 1 auto;

            // height: 42rem;

            & .input {
                background: #f3f7f9;
                border: 0;
                border-radius: 12rem;
            }

            & .input__support {
                font-weight: 300;
            }

            // & .input__support,
            // & .input__value {
            //     @include mobile {
            //         font-size: 16rem;
            //     }
            // }
        }

        &Actions {
            margin-left: 8rem;

            @include mobile {
                margin-left: 10rem;
            }
        }

        &ActionWrapper {
            position: relative;
            width: 40rem;
            height: 40rem;

            @include mobile {
                width: 26rem;
                height: 40rem;
            }

            & + & {
                margin-left: 6rem;

                @include mobile {
                    margin-left: 10rem;
                }
            }
        }

        &Action {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            border-radius: 12rem;

            @include button(0.95);

            @include mobile {
                background: none !important;
                border-radius: 0;
            }

            &Icon {
                display: block;
                width: 20rem;
                height: 20rem;
                margin: auto;
                transition: $trans;

                @include mobile {
                    width: 28rem;
                    height: 28rem;
                }
            }

            &._loading &Icon {
                @include mobile {
                    opacity: 0;
                }
            }

            &._emodzi {
                background: #ebf7ff;

                @include icon($colorMain);
            }

            &._attach {
                background: #f3f7f9;

                @include icon(#9da8b4);

                &._active {
                    @include icon($colorMain);
                }
            }

            &._send {
                background: $colorMain;

                @include icon(#fff);

                @include mobile {
                    @include icon($colorMain);
                }

                &:not(._ready) {
                    background: #f3f7f9;

                    @include icon(#9da8b4);
                }
            }

            &._send &Icon {
                width: 17rem;
                height: 17rem;

                @include mobile {
                    width: 24rem;
                    height: 24rem;
                }
            }

            &Loader {
                background: $colorMain;
                border-radius: inherit;

                @include mobile {
                    background: none;
                    border-radius: 0;

                    @include loader($colorMain);
                }

                & ._LOADERITEM {
                    width: 20rem;
                    height: 20rem;
                }
            }

            &Counter {
                position: absolute;
                top: -4rem;
                left: -4rem;
                width: 14rem;
                height: 14rem;
                background: $colorMain;
                border-radius: 5rem;

                &Inner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-weight: 600;
                    font-size: 11rem;
                    white-space: nowrap;
                    text-align: center;

                    &._child {
                        padding-top: 1rem;
                    }
                }
            }
        }
    }
}
