.publicIndex {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    @include mobile {
        padding-top: 104rem !important;
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 60%;
        background: linear-gradient(to bottom, #f9fafa, rgba(#eef1f2, 0));
        content: "";
        pointer-events: none;

        @include mobile {
            background: linear-gradient(to bottom, #eef2f3, rgba(#eef1f2, 0));
        }
    }

    &__inner {
        position: relative;
        z-index: 2;
        align-items: center;
        height: 100%;
    }

    &__logo {
        margin-bottom: 42rem;
    }

    &__title {
        margin-bottom: 12rem;
        font-size: 48rem;
        line-height: 1.1;
        text-align: center;

        @include mobile {
            margin-bottom: 8rem;
            font-size: 34rem;
            line-height: 1.2;
        }
    }

    &__description {
        max-width: 70%;
        margin-bottom: 20rem;
        font-size: 17.5rem;
        line-height: 1.5;
        text-align: center;

        @include mobile {
            max-width: 93%;
            margin-bottom: 24rem;
            font-size: 16rem;
        }
    }

    &__button {
        width: 214rem;

        @include mobile {
            width: 256rem;
        }
    }

    &__rights {
        margin-top: auto;
        font-weight: 300;
        font-size: 11rem;
        text-align: center;

        @include mobile {
            font-size: 13rem;
        }
    }
}
