.profileMobHead {
    align-items: center;
    width: 100%;

    &__top {
        align-items: center;
        width: 100%;
    }

    &__icon {
        width: 32rem;
        height: 32rem;
        margin: 0 0 8rem;

        @include icon(#b9c5d3);
    }

    &__title {
        max-width: 90%;
        margin-bottom: 8rem;
        color: $colorDark;
        font-size: 28rem;
        line-height: 1.2;
        text-align: center;
    }

    &__description {
        max-width: 90%;
        color: $colorDark;
        font-weight: 300;
        font-size: 16rem;
        line-height: 1.4;
        text-align: center;
    }

    &__button {
        width: 100%;
        margin-top: 20rem;
    }
}
