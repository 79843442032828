.publicLegalArticle {
    width: 100%;

    &__inner {
        align-items: center;
    }

    &__box {
        width: 100%;
        padding: 84rem 192rem 0 374rem;

        @include mobile {
            padding: 0;
        }

        &Inner {
            position: relative;
            width: 100%;
        }
    }

    &__loader {
        @include loader($colorMain);

        height: calc(100vh - 250rem);
    }

    &__head {
        width: 100%;
        margin-bottom: 32rem;

        &Title {
            max-width: 70%;
            margin-bottom: 10rem;
            font-weight: 500;
            font-size: 34rem;
            line-height: 1.3;

            @include mobile {
                max-width: 100%;
                font-weight: 400;
                font-size: 26rem;
            }
        }

        &Description {
            color: $colorGrey;
            font-size: 16rem;
            line-height: 1.3;

            @include mobile {
                font-size: 16rem;
            }

            & span {
                color: $colorGrey;
                font-weight: 400;
                font-size: 16rem;

                @include mobile {
                    font-size: 16rem;
                }
            }
        }
    }

    &__content {
        width: 100%;
        transition: $trans;
    }

    &:not(._ready) &__content {
        opacity: 0;
    }

    &__text {
        &,
        & * {
            font-weight: 300;
            font-size: 16rem;

            @include mobile {
                font-size: 16rem;
            }
        }

        & h2,
        & h2 * {
            font-weight: 400;
            font-size: 24rem;
        }

        & h3,
        & h3 * {
            font-weight: 400;
            font-size: 20rem;
        }

        & table {
            @include mobile {
                display: block;
                width: 100%;
                overflow-x: auto;
            }
        }
    }
}
