.more {
    display: flex;
    width: 100%;
    height: 100%;
    background: #f3f7f9;
    border-radius: 6rem;

    @include mobile {
        border-radius: 7rem;
    }

    @include hover {
        &:hover {
            @include icon($colorMain);
        }
    }

    @include button(0.95);

    &__icon {
        display: block;
        width: 80%;
        height: 80%;
        margin: auto;

        @include icon($colorDark);
    }
}
