.error {
    color: $colorAlert;
    font-size: 15rem;
    line-height: 1.3;
    text-align: center;

    @include mobile {
        font-size: 16rem;
    }
}
