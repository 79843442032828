.addButton {
    position: relative;
    width: 100%;
    padding-left: 22rem;
    color: $colorMain;
    font-weight: 500;
    font-size: 15.5rem;

    @include mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56rem;
        padding: 0 20rem 0 32rem;
        color: #fff;
        font-size: 17rem;
        background: $colorMain;
        border-radius: 16rem;
    }

    @include hover {
        &:hover {
            color: $colorMain2;

            @include icon($colorMain2);
        }
    }

    @include button;

    &__icon {
        position: absolute;
        top: 50%;
        left: 0;
        width: 16rem;
        height: 16rem;
        transform: translate(0, -50%);

        @include mobile {
            position: relative;
            top: 0;
            width: 20rem;
            height: 20rem;
            margin: 0 10rem 0 -16rem;
            transform: translate(0, 0);

            @include icon(#fff);

            & ._add2 {
                fill: $colorMain;
            }
        }
    }
}
