.publicMenu {
    width: 100%;
    height: 100%;

    &__inner {
        align-items: center;
        width: 100%;
        min-height: 100%;
        padding: 70rem 0 36rem;

        @include mobile {
            padding: 100rem 0 32rem;
            overflow-y: auto;
        }
    }

    &__content {
        align-items: center;
        width: 100%;
        margin: auto 0;
        padding: 0 32rem;

        @include mobile {
            padding: 0 20rem;
        }
    }

    &__nav {
        align-items: center;
        padding-top: 40rem;

        @include mobile {
            padding-top: 0;
        }

        &Item {
            & + & {
                margin-top: 44rem;

                @include mobile {
                    margin-top: 26rem;
                }
            }
        }

        &Link {
            position: relative;
            color: $colorDark;
            font-weight: 300;
            font-size: 45rem;

            @include mobile {
                font-size: 28rem;
                line-height: 1.3;
                text-align: center;
            }

            @include hover {
                &:hover {
                    color: $colorMain;
                }
            }

            @include button(0.96);

            &._current {
                color: $colorMain;
                font-weight: 400;
            }
        }

        &Counter {
            position: absolute;
            top: 0;
            left: 100%;
            z-index: 2;
            margin: -2rem 0 0 2rem;
            pointer-events: none;
        }
    }

    &__auth {
        margin-top: 40rem;

        @include mobile {
            margin-top: 24rem;
        }
    }

    &__button {
        @include mobile {
            width: 100%;
            height: 56rem;
            margin-top: 32rem;

            & + & {
                margin-top: 12rem;
            }
        }
    }

    &__rights {
        margin-top: auto;
        padding-top: 22rem;
        font-weight: 300;
        font-size: 11rem;
        text-align: center;

        @include mobile {
            margin-top: 0;
            padding-top: 70rem;
            font-size: 13rem;
        }
    }
}
