.counterButton {
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 13rem;
    background: #f1f9ff;
    border-radius: 14rem;

    @include mobile {
        justify-content: space-between;
        padding: 12rem 14rem;
        border-radius: 16rem;
    }

    &__button {
        width: 20rem;
        height: 20rem;
        background: $colorMain;
        border-radius: 6rem;

        @include mobile {
            width: 24rem;
            height: 24rem;
            border-radius: 8rem;
        }

        @include button(0.9);

        &Icon {
            display: block;
            width: 8rem;
            height: 8rem;
            margin: auto;

            @include mobile {
                width: 10rem;
                height: 10rem;
            }

            @include icon(#fff);
        }

        @include hover {
            &:hover {
                background: $colorMain2;
            }
        }
    }

    &__input {
        width: 40rem;
        height: 18rem;
        margin: 0 10rem;
        font-size: 16rem;
        text-align: center;

        @include mobile {
            width: calc(100% - 70rem);
            height: auto;
            font-size: 18rem;
        }
    }
}
