.orderServices {
    width: 100%;

    &__service {
        width: 100%;
        height: 100rem;
        transition: $trans;

        @include mobile {
            align-items: center;
            height: auto;
            min-height: 56rem;
            padding: 6rem;
            background: #fff;
            border-radius: 18rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);
        }

        @include hover {
            &:not(._info):hover {
                transform: translate(0, -4rem);
            }
        }

        & + & {
            margin-top: 12rem;

            @include mobile {
                margin-top: 8rem;
            }
        }

        &:not(._active) {
            opacity: 0.64;
        }

        &Block {
            justify-content: center;
            width: calc(100% - 75% - 2rem);
            height: 100%;
            padding: 28rem 32rem;
            background: #fff;
            border-radius: 2rem 16rem 16rem 2rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);

            @include mobile {
                width: 130rem;
                padding: 0;
                background: none;
                border-radius: 0;
                box-shadow: none;
            }

            &._content {
                width: 75%;
                margin-right: 2rem;
                padding-left: 38rem;
                border-radius: 16rem 2rem 2rem 16rem;

                @include mobile {
                    width: calc(100% - 130rem);
                    margin-right: auto;
                    margin-bottom: 2rem;
                    padding: 0;
                    border-radius: 0;
                }
            }
        }

        &Name {
            margin-bottom: 8rem;
            font-size: 19rem;

            @include mobile {
                width: 100%;
                margin-bottom: 0;
                padding: 2rem 10rem 2rem 12rem;
                font-weight: 300;
                font-size: 18rem;
                line-height: 1.3;
            }
        }

        &Description {
            font-weight: 300;
            font-size: 14rem;

            @include mobile {
                display: none;
                font-size: 18rem;
                line-height: 1.3;
            }
        }

        &Box {
            // width: 100%;

            @include mobile {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                height: 44rem;
                margin-left: auto;
            }
        }

        &._sms &Box {
            @include mobile {
                height: auto;
            }
        }

        &Input {
            width: 120rem;
            height: 44rem;

            @include mobile {
                width: 100%;
                height: 100%;
            }

            & .input {
                background: #f1f9ff;
            }

            & .input__support,
            & .input__field {
                color: $colorDark;

                @include mobile {
                    justify-content: center;
                    font-size: 18rem;
                    text-align: center;
                }
            }
        }

        &Switch {
            align-items: center;

            &Box {
                margin-right: 8rem;

                @include mobile {
                    margin-right: 0;
                }
            }

            &Support {
                color: #70a6cb;
                font-weight: 300;
                font-size: 15rem;
                white-space: nowrap;
                transition: $trans;

                @include mobile {
                    font-size: 20rem;
                }

                &._active {
                    color: $colorDark;
                    font-weight: 500;
                }
            }
        }
    }
}
