.profileChatsList {
    width: 100%;
    height: 100%;

    &__head {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 18rem 24rem 0;

        @include mobile {
            padding: 0;
        }

        &::after {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 64rem;
            background: linear-gradient(to bottom, #f9fbfc, rgba(#f9fbfc, 0));
            opacity: 0;
            transition: $trans;
            content: "";
            pointer-events: none;
        }

        &Inner {
            height: 40rem;

            @include mobile {
                height: 50rem;
            }
        }

        &Search {
            width: calc(100% - 40rem - 6rem);
            height: 100%;

            @include mobile {
                width: calc(100% - 50rem - 6rem);
            }
        }

        &Filter {
            width: 40rem;
            height: 100%;
            margin-left: 6rem;

            @include mobile {
                width: 50rem;
            }
        }
    }

    &._scrollFix &__head::after {
        opacity: 1;
    }

    &__items {
        position: relative;
        width: 100%;
        height: calc(100% - 40rem - 18rem);

        @include mobile {
            height: auto;
            min-height: calc(var(--wheight) - 50rem - 90rem - 22rem);
        }

        &Loader {
            @include loader($colorMain);
        }

        &ScrollLoader {
            @include loader($colorMain);
        }

        &Box {
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        &Scroll {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0 24rem;
            overflow-y: auto;

            @include mobile {
                height: auto;
                padding: 0;
                overflow: visible;
            }
        }

        &Previews {
            width: 100%;
            padding: 18rem 0;

            @include mobile {
                padding-bottom: 0;
            }
        }

        &Preview {
            width: 100%;
        }

        &Previews._static &Preview:not(._last) {
            margin-bottom: 8rem;

            @include mobile {
                margin-bottom: 12rem;
            }
        }
    }

    &._loading &__itemsBox {
        opacity: 0;
    }
}
