.profileWallets {
    width: 100%;

    &__head {
        width: 100%;
        height: 138rem;
        margin-bottom: 16rem;

        @include mobile {
            flex-wrap: wrap;
            justify-content: space-between;
            height: auto;
        }

        &Block {
            height: 100%;

            @include mobile {
                height: auto;
            }

            &._info {
                width: 62%;
                margin-right: auto;

                @include mobile {
                    width: 100%;
                    margin-bottom: 16rem;
                }
            }

            &._action {
                width: 170rem;
                margin-left: 8rem;

                @include mobile {
                    width: calc(50% - 6rem);
                    margin-left: 0;
                }
            }
        }
    }

    &__content {
        width: 100%;
    }

    &__items {
        width: 100%;
    }

    &__item {
        width: 100%;
    }

    &__items._static &__item:not(._last) {
        margin-bottom: 10rem;

        @include mobile {
            margin-bottom: 12rem;
        }
    }

    &__balance {
        width: 100%;
        height: 100%;

        @include mobile {
            flex-direction: column;
            height: auto;
        }

        &Block {
            width: calc(100% - 270rem - 1rem);
            height: 100%;
            padding: 34rem 37rem;
            background: #fff;
            border-radius: 22rem 2rem 2rem 22rem;
            box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);

            @include mobile {
                width: 100%;
                height: auto;
                padding: 22rem;
                border: 3rem solid #fff;
                border-radius: 24rem 24rem 2rem 2rem;
            }

            &._total {
                &._full {
                    width: 100%;
                    border-radius: 22rem;
                }

                @include mobile {
                    margin-bottom: 2rem;
                    padding: 20rem 22rem;
                }
            }

            &._actions {
                width: 270rem;
                margin-left: auto;
                border-radius: 2rem 22rem 22rem 2rem;

                @include mobile {
                    width: 100%;
                    background: rgba(#fff, 0.32);
                    border-radius: 2rem 2rem 24rem 24rem;
                }
            }
        }

        &Total {
            height: 38rem;
            margin: 6rem 0 4rem;

            @include mobile {
                height: 32rem;
                margin: 0 0 8rem;
            }

            &Inner {
                color: $colorDark;
                font-weight: 500;
                font-size: 38rem;
                white-space: nowrap;

                @include mobile {
                    font-size: 32rem;
                }
            }

            & .animateChange__loader {
                width: 32rem;
                height: 32rem;
            }
        }

        &Link {
            position: relative;
            z-index: 2;
            margin-top: auto;
            padding-right: 20rem;
            color: $colorMain;
            font-size: 16rem;

            @include mobile {
                padding-right: 22rem;
                font-size: 16rem;
            }

            @include hover {
                &:hover {
                    color: $colorMain2;
                }

                &:hover &Icon {
                    transform: translate(4rem, -50%);

                    @include icon($colorMain2);
                }
            }

            @include button(0.97);

            &Icon {
                position: absolute;
                top: 50%;
                right: 0;
                width: 13rem;
                height: 13rem;
                transform: translate(0, -50%);
                transition: $trans;

                @include mobile {
                    width: 14rem;
                    height: 14rem;
                }

                @include icon($colorMain);
            }
        }

        &Button {
            // width: 100%;
            margin-bottom: 12rem;

            @include mobile {
                width: 100%;
                margin-bottom: 16rem;
            }

            & .button {
                padding-right: 20rem;
                padding-left: 20rem;

                @include icon(#fff);
            }

            & ._add2 {
                fill: $colorMain;
            }
        }

        &LastDate {
            display: flex;
            margin-left: 7rem;
            color: $colorGrey;
            font-size: 13rem;

            @include mobile {
                justify-content: center;
                height: 14rem;
                margin: 0 auto;
                font-size: 14rem;
                text-align: center;
            }

            & b {
                margin-right: 5rem;
                color: $colorGrey;
                font-weight: 600;
                font-size: 13rem;

                @include mobile {
                    font-size: 14rem;
                }
            }

            &Inner {
                color: $colorGrey;
                font-size: 13rem;
                white-space: nowrap;

                @include mobile {
                    font-size: 14rem;
                }

                & .animateChange__loader {
                    width: 20rem;
                    height: 20rem;
                }
            }
        }
    }

    &__action {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 26rem 24rem;
        background: #fff;
        border-radius: 22rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);

        &._disabled {
            pointer-events: none;
        }

        &._disabled &Icon,
        &._disabled &Title,
        &._disabled &Description,
        &._disabled &Arrow {
            opacity: 0.5;

            @include mobile {
                opacity: 0.4;
            }
        }

        @include mobile {
            height: auto;
            padding: 24rem;
            border-radius: 24rem;
        }

        @include hover {
            &:hover &Title {
                color: $colorMain;
            }

            &:hover &Icon {
                @include icon($colorMain);
            }

            &:hover &Arrow {
                transform: translate(4rem, 0);
            }
        }

        @include button(0.97);

        &Icon {
            width: 20rem;
            height: 20rem;
            margin-bottom: auto;

            @include mobile {
                width: 22rem;
                height: 22rem;
                margin-bottom: 32rem;
            }
        }

        &Title {
            margin-bottom: 8rem;
            font-weight: 500;
            font-size: 19rem;
            transition: $trans;

            @include mobile {
                font-size: 18rem;
            }
        }

        &Description {
            display: flex;
            max-width: 100%;
            color: $colorGrey;
            font-size: 13rem;

            @include mobile {
                height: 16rem;
                font-size: 16rem;
            }

            &Inner {
                color: $colorGrey;
                font-size: 13rem;
                white-space: nowrap;

                @include mobile {
                    font-size: 16rem;
                }

                &._parent {
                    margin-left: 4rem;
                }

                & .animateChange__loader {
                    width: 16rem;
                    height: 16rem;
                }
            }
        }

        &Arrow {
            position: absolute;
            right: 24rem;
            bottom: 26rem;
            z-index: 2;
            width: 14rem;
            height: 14rem;
            transition: $trans;

            @include mobile {
                top: 28rem;
                right: 22rem;
                bottom: auto;
                width: 14rem;
                height: 14rem;
            }

            @include icon($colorMain);
        }
    }
}
