.profileChatsPreview {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 14rem 12rem 14rem 16rem;
    background: #fff;
    border-radius: 14rem;
    will-change: transform;

    @include mobile {
        padding: 16rem 12rem 16rem 20rem;
        border-radius: 18rem;
    }

    @include button(0.96);

    &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 2rem;
        height: 40rem;
        border-radius: 0 10rem 10rem 0;
        transform: translate(0, -50%);
        transition: $trans;
        content: "";
    }

    &._important::before {
        background: $colorAlert;
    }

    &._process::before {
        background: #ff9c20;
    }

    &._closed::before {
        background: $colorSuccess;
    }

    &._current {
        box-shadow: 0 1rem 4rem rgba($colorDark, 0.08);
    }

    &__inner {
        position: relative;
        align-items: center;
    }

    &__avatar {
        position: relative;
        width: 54rem;
        height: 54rem;

        @include mobile {
            width: 60rem;
            height: 60rem;
        }

        &Inner {
            width: 100%;
            height: 100%;

            & .avatar__text {
                font-size: 19rem;

                @include mobile {
                    font-size: 20rem;
                }
            }
        }

        &Responsible {
            position: absolute;
            bottom: -6rem;
            left: -6rem;
            z-index: 2;
            width: 26rem;
            height: 26rem;
            pointer-events: none;

            @include mobile {
                bottom: -8rem;
                left: -8rem;
                width: 32rem;
                height: 32rem;
            }

            &Inner {
                width: 100%;
                height: 100%;
                pointer-events: visible;

                &._child {
                    border: 2rem solid #fff;
                    border-radius: 50%;
                }

                & .avatar__text {
                    font-size: 10rem;

                    @include mobile {
                        font-size: 12rem;
                    }
                }
            }
        }
    }

    &._current &__avatarInner .avatar__inner {
        background: rgba($colorMain, 0.1) !important;
    }

    &._current &__avatarInner .avatar__text {
        color: $colorMain !important;
    }

    &__content {
        width: calc(100% - 54rem);
        padding: 0 0 0 10rem;

        @include mobile {
            width: calc(100% - 60rem);
            padding-left: 14rem;
        }
    }

    &__title {
        max-width: 100%;
        margin-bottom: 3rem;
        padding-right: 20rem;
        overflow: hidden;
        font-weight: 500;
        font-size: 16rem;
        line-height: 1.3;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: $trans;

        @include mobile {
            font-size: 18rem;
        }
    }

    &._current &__title {
        color: $colorMain;
    }

    &__subTitle {
        max-width: 100%;
        margin-bottom: 5rem;
        color: $colorGrey;
        font-weight: 300;
        font-size: 12rem;

        @include mobile {
            font-size: 13rem;
        }
    }

    &__text {
        width: 100%;
        height: 17rem;
        padding-right: 40rem;

        &Content {
            width: 100%;
            overflow: hidden;
            font-weight: 300;
            font-size: 14rem;
            line-height: 1.4;
            white-space: nowrap;
            text-overflow: ellipsis;

            @include mobile {
                font-size: 16rem;
            }
        }

        &File {
            display: flex;
            align-items: center;
            font-weight: 300;
            font-size: 14rem;
            line-height: 1.4;

            @include mobile {
                font-size: 16rem;
            }

            &Preview {
                z-index: 1;
                width: 18rem;
                height: 18rem;
                margin: 0 4rem 0 2rem;
                overflow: hidden;
                background: #f1f5f7;
                border-radius: 4rem;
            }
        }
    }

    &__time {
        position: absolute;
        right: 4rem;
        bottom: 0;
        color: #afccde;
        font-size: 12rem;
        white-space: nowrap;

        @include mobile {
            font-size: 14rem;
        }
    }

    &__counter {
        position: absolute;
        top: -4rem;
        right: -4rem;
        z-index: 2;

        @include mobile {
            right: 0;
        }
    }

    &__read {
        position: absolute;
        top: 6rem;
        right: 1rem;
        z-index: 2;
        width: 14rem;

        @include mobile {
            right: 0;
            width: 16rem;
        }

        &:not(._completed) {
            @include icon($colorGrey);
        }
    }
}
