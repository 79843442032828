.chatBtn {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 20rem;
    box-shadow: 0 16rem 24rem rgba($colorDark, 0.08);

    @include button(0.96);

    &__icon {
        display: block;
        width: 24rem;
        height: 24rem;
        margin-left: 2rem;

        @include mobile {
            width: 28rem;
            height: 28rem;
        }

        @include icon($colorMain);
    }

    &__counter {
        position: absolute;
        top: -4rem;
        right: -4rem;
    }
}
