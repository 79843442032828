.orderRoute {
    position: relative;
    z-index: 2;
    width: 100%;

    &__map {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 400rem;
        margin-bottom: 2rem;
        overflow: hidden;
        background: #f6f6f6;
        border: 2rem solid #fff;
        border-radius: 16rem 16rem 2rem 2rem;
        box-shadow: 0 2rem 8rem rgba(#000, 0.02);
        transition: $trans;

        @include mobile {
            height: 188rem !important;
            border: 0;
            border-radius: 24rem 24rem 2rem 2rem;
        }

        &._active {
            height: 350rem;
        }
    }

    &._final &__map,
    &._check &__map,
    &._details &__map,
    &._out &__map {
        height: 300rem !important;

        @include mobile {
            height: 188rem !important;
        }
    }

    &._details &__map {
        @include mobile {
            height: 188rem !important;
        }
    }

    &._check &__route {
        margin-bottom: 2rem;

        &Item {
            position: relative;
            z-index: 1;

            &Inner {
                border-radius: 2rem 2rem 16rem 16rem;
            }
        }
    }

    &._details &__route {
        &Item {
            position: relative;
            z-index: 1;
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &._details &__routeItem:last-child &__routeItemInner {
        border-radius: 2rem 2rem 16rem 16rem;

        @include mobile {
            border-radius: 2rem 2rem 16rem 16rem;
        }
    }

    &__route {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-bottom: 14rem;
        transition: $trans;

        &._static {
            display: flex;
            flex-direction: column;
        }

        &._static &Item {
            position: relative;

            &:not(._last) {
                margin-bottom: 2rem;
            }

            &._hide {
                display: none;
            }
        }

        &._process &Item:not(._current) {
            opacity: 0.64;
        }

        &Item {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            transition: $trans;

            &._hide {
                opacity: 0;
            }

            &._current {
                z-index: 1000 !important;
                transition: none;
            }

            &Inner {
                width: 100%;
                background: #fff;
                border-radius: 2rem;
                box-shadow: 0 2rem 8rem rgba(#000, 0.02);
                transition: $trans;
            }

            &._last &Inner {
                border-radius: 2rem 2rem 16rem 16rem;

                @include mobile {
                    border-radius: 2rem 2rem 16rem 16rem;
                }
            }

            &._current &Inner {
                box-shadow: 0 2rem 8rem rgba(#000, 0.1);
            }
        }
    }

    &__foot {
        align-items: center;
        width: 100%;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &._final &__button._date,
    &._details &__button._date {
        margin-right: 24rem;
        margin-left: 0;

        @include mobile {
            margin-right: 0;
        }
    }

    &__button {
        height: 56rem;
        padding: 6rem 20rem;
        background: #fff;
        border-radius: 15rem;

        @include mobile {
            display: flex;
            align-items: center;
            width: 100%;
            height: 56rem;
            padding: 10rem 30rem 12rem;
            border-radius: 16rem;
        }

        @include button;

        &._disabled {
            opacity: 0.74;
        }

        &._add {
            margin-right: 2rem;
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;

            @include mobile {
                margin-right: 0;
                margin-bottom: 2rem;
                border-radius: 16rem 16rem 2rem 2rem;
            }
        }

        &._optimization {
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;

            @include mobile {
                margin-bottom: 12rem;
                border-radius: 2rem 2rem 16rem 16rem;
            }
        }

        &._date {
            margin-left: auto;
        }

        &Inner {
            align-items: center;
            height: 100%;
            color: $colorMain;
            font-size: 15.5rem;
            transition: $trans;

            @include mobile {
                font-size: 18rem;
            }
        }

        &._disabled &Inner {
            opacity: 0.5;
        }

        &Icon {
            width: 17rem;
            height: 17rem;
            margin-right: 7rem;
            transition: $trans;

            @include mobile {
                width: 18rem;
                height: 18rem;
                margin-right: 12rem;
            }
        }

        &._optimization._completed &Icon {
            @include icon($colorAlert);

            transform: rotate(90deg);
        }

        &Content {
            color: $colorMain;
            font-size: 15.5rem;
            line-height: 1;
            white-space: nowrap;
            transition: $trans;

            @include mobile {
                font-size: 18rem;
            }

            &._child {
                padding-top: 2rem;
            }
        }

        &._optimization &Content {
            color: $colorMain;
        }

        &._optimization._completed &Content {
            color: $colorAlert;
        }

        &._date &Icon {
            @include icon(#9ab2be);
        }

        &._date &Content {
            color: #9ab2be;
        }

        &._completed._date &Icon {
            @include icon($colorMain);
        }

        &._completed._date &Content {
            color: $colorDark;
        }

        @include hover {
            &:not(._date):hover &Content {
                opacity: 0.64;
            }

            &:not(._date):hover &Icon {
                opacity: 0.64;
            }

            &._date:hover &Content {
                color: $colorMain;
            }

            &._date:hover &Icon {
                @include icon($colorMain);
            }
        }
    }

    &__info {
        align-items: center;
        padding-right: 24rem;

        @include mobile {
            width: 100%;
            margin-top: 24rem;
            padding-right: 0;
        }

        &Inner {
            @include mobile {
                flex-direction: column;
                align-items: center;
            }
        }

        &Item {
            align-items: center;
            font-weight: 300;
            font-size: 15rem;
            white-space: nowrap;

            @include mobile {
                font-size: 18rem;
            }

            &._grey {
                color: $colorGrey;
                font-weight: 400;
            }

            & + & {
                margin-left: 14rem;

                @include mobile {
                    margin-top: 16rem;
                    margin-left: 0;
                }
            }

            & span {
                font-weight: 400;
                font-size: 15rem;

                @include mobile {
                    font-size: 18rem;
                }
            }
        }
    }
}
