.publicBlogInner {
    width: 100%;
    padding: 158rem 0 0;

    @include mobile {
        padding-top: 120rem;
    }

    &__inner {
        position: relative;
    }

    &__head {
        align-items: center;
        margin-bottom: 25rem;

        &._mob {
            position: absolute;
            top: 0;
            right: 0;

            @include mobile {
                justify-content: flex-end;
                padding-top: 0;
            }
        }

        @include mobile {
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 34rem;
        }

        &Info {
            align-items: center;
            color: $colorGrey;
            font-size: 17rem;

            @include mobile {
                font-size: 16rem;
                line-height: 1.3;
            }

            &Icon {
                width: 18rem;
                height: 18rem;
                margin-right: 6rem;

                @include icon(rgba($colorGrey, 0.72));

                @include mobile {
                    width: 20rem;
                    height: 20rem;
                    margin-top: -1rem;
                }
            }

            &._time &Icon {
                transform: scale(0.8);
            }

            & + & {
                margin-left: 26rem;

                @include mobile {
                    margin-left: 0;
                }
            }

            &._left {
                margin-left: auto;

                @include mobile {
                    margin-left: 0;
                }
            }
        }
    }

    &__loader {
        z-index: 2;
        height: 600rem;

        @include loader($colorMain);
    }

    &__content {
        position: relative;
        align-items: center;
        width: 100%;

        @include mobile {
            align-items: flex-start;
        }

        &Inner {
            width: 960rem;
            min-height: calc(100vh - 200rem);
            transition: $trans;

            @include mobile {
                width: 100%;
                min-height: calc(100vh - 160rem);
            }
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__back {
        position: sticky;
        top: 140rem;
        width: 100%;
        height: 20rem;
        margin-bottom: -20rem;

        @include mobile {
            position: relative;
            top: 0;
            width: auto;
            height: auto;
            margin-bottom: 0;
        }

        &Button {
            align-items: center;
            color: $colorMain;
            font-size: 16rem;

            @include mobile {
                font-size: 18rem;
            }

            &Icon {
                width: 15rem;
                height: 15rem;
                margin-right: 6rem;

                @include icon($colorMain);

                @include mobile {
                    width: 20rem;
                    height: 20rem;
                    margin-right: 6rem;
                }
            }
        }
    }

    &__article {
        width: 100%;
        padding-bottom: 100rem;

        @include mobile {
            padding-bottom: 64rem;
        }

        &Title {
            margin-bottom: 28rem;
            color: $colorDark;
            font-size: 46rem;
            line-height: 1.25;

            @include mobile {
                margin-bottom: 24rem;
                font-size: 26rem;
            }
        }

        &Image {
            display: block;
            width: 100%;
            margin-bottom: 24rem;
            border-radius: 24rem;

            @include mobile {
                width: calc(100% + 40rem);
                min-height: 260rem;
                margin-left: -20rem;
                object-fit: cover;
                border-radius: 0;
            }
        }

        &Inner {
            &,
            & * {
                font-weight: 300;
                font-size: 18rem;

                @include mobile {
                    font-size: 16rem;
                }
            }

            & h2,
            & h2 * {
                font-weight: 400;
                font-size: 32rem;

                @include mobile {
                    font-size: 24rem;
                }
            }

            & h3,
            & h3 * {
                font-weight: 400;
                font-size: 26rem;

                @include mobile {
                    font-size: 20rem;
                }
            }

            & ._ancor {
                color: $colorMain;
                font-weight: 400;

                & * {
                    color: $colorMain;
                    font-weight: 400;
                }

                // font-weight: 600;
            }

            & img {
                position: relative;
                z-index: 1;
                overflow: hidden;
                border-radius: 24rem;

                @include mobile {
                    width: 100% !important;
                }
            }
        }
    }

    &__foot {
        align-items: stretch;
        width: 100%;

        &Block {
            width: calc(50% - 5rem);
            padding: 80rem;
            background: rgba($colorMain, 0.072);

            @include mobile {
                position: relative;
                width: calc(50% - 3rem);
                padding: 20rem 14rem 50rem;
            }

            & + & {
                margin-left: 10rem;

                @include mobile {
                    margin-left: 6rem;
                }
            }

            &._one {
                align-items: center;
                width: 100%;

                @include mobile {
                    border-radius: 10rem 10rem 0 0;
                }

                & * {
                    text-align: center;
                }
            }

            &:not(._one) {
                &._prevArticle {
                    align-items: flex-end;
                    border-radius: 0 10rem 10rem 0;

                    @include mobile {
                        padding-left: 10rem;
                        border-radius: 0 10rem 0 0;
                    }
                }

                &._nextArticle {
                    border-radius: 10rem 0 0 10rem;

                    @include mobile {
                        padding-right: 10rem;
                        border-radius: 10rem 0 0;
                    }
                }
            }

            &Inner {
                width: 330rem;

                @include mobile {
                    width: 100%;
                }
            }

            &._one &Inner {
                align-items: center;
            }

            &:not(._one)._prevArticle &Inner {
                align-items: flex-end;

                & * {
                    text-align: right;
                }
            }

            &._one &Title {
                // margin-left: -1rem;
            }

            &Title {
                position: relative;
                max-width: 100%;
                margin-bottom: 20rem;
                color: $colorDark;
                font-weight: 500;
                font-size: 20rem;

                @include mobile {
                    position: static;
                    margin-bottom: 10rem;
                    font-size: 18rem;
                    line-height: 1.3;
                }

                &Icon {
                    position: absolute;
                    top: 50%;
                    width: 20rem;
                    height: 20rem;
                    transform: translate(0, -50%);

                    @include icon($colorMain);

                    @include mobile {
                        top: auto;
                        bottom: 16rem;
                        width: 20rem;
                        height: 20rem;
                        transform: translate(0, 0);
                    }
                }
            }

            &._prevArticle &Title {
                padding-left: 35rem;

                @include mobile {
                    padding-left: 0;
                }

                &Icon {
                    left: 0;

                    @include mobile {
                        right: 14rem;
                        left: auto;
                    }
                }
            }

            &._nextArticle &Title {
                padding-right: 35rem;

                @include mobile {
                    padding-right: 0;
                }

                &Icon {
                    right: 0;

                    @include mobile {
                        left: 14rem;
                    }
                }
            }

            &._one &TitleIcon {
                @include mobile {
                    right: auto;
                    left: 50% !important;
                    transform: translate(-50%, 0);
                }
            }

            &Name {
                max-width: 100%;
                color: $colorDark;
                font-weight: 300;
                font-size: 18rem;
                line-height: 1.4;

                @include mobile {
                    font-size: 16rem;
                    line-height: 1.5;
                }
            }
        }
    }
}
