.search {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 12rem 16rem 12rem 44rem;
    background: #fff;
    border-radius: 14rem;
    cursor: text;

    &._mediumSize {
        padding: 6rem 16rem 6rem 36rem;
        border-radius: 12rem;

        @include mobile {
            padding-left: 40rem;
            border-radius: 14rem;
        }
    }

    @include mobile {
        padding: 12rem 20rem 12rem 50rem;
        border-radius: 20rem;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 17rem;
        width: 16rem;
        height: 16rem;
        transform: translate(0, -50%);

        @include icon($colorGrey);

        @include mobile {
            left: 20rem;
            width: 18rem;
            height: 18rem;
        }
    }

    &._mediumSize &__icon {
        left: 15rem;
        width: 15rem;
        height: 15rem;

        @include mobile {
            width: 16rem;
            height: 16rem;
        }
    }

    &__field {
        width: 100%;
        height: 100%;
        padding-top: 2rem;

        @include mobile {
            padding-top: 0;
        }
    }

    &._mediumSize &__field .input__value,
    &._mediumSize &__field .input__support {
        font-weight: 300;
    }
}
