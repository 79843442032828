.profileWallet {
    position: relative;
    width: 100%;
    padding: 24rem 28rem;
    background: #f2f7fa;
    border-radius: 16rem;
    transition: $trans;

    @include mobile {
        padding: 20rem;
        border: 3rem solid #fff;
        border-radius: 24rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);
    }

    &._account {
        padding-bottom: 16rem;

        @include mobile {
            padding-bottom: 20rem;
        }
    }

    &._current {
        background: #edf8ff;

        @include mobile {
            padding-top: 50rem;
            background: #f0f9ff;
        }
    }

    &__items {
        width: 100%;
        margin-bottom: -16rem;

        @include mobile {
            flex-direction: column;
            margin-bottom: 0;
        }
    }

    &__item {
        margin: 0 26rem 16rem 0;

        @include mobile {
            flex-direction: column-reverse;
            margin: 0;

            & + & {
                margin-top: 14rem;
            }
        }

        &Support {
            margin-bottom: 4rem;
            color: $colorGrey;
            font-weight: 400;
            font-size: 15rem;

            @include mobile {
                margin-top: 4rem;
                margin-bottom: 0;
                color: $colorDark;
                font-weight: 300;
                font-size: 16rem;
            }
        }

        &Value {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 28rem;
            font-weight: 300;
            font-size: 16rem;

            @include mobile {
                font-weight: 500;
                font-size: 18rem;
                line-height: 1.3;
            }
        }

        &._line &Support,
        &._line &Value {
            padding-left: 26rem;

            @include mobile {
                padding-left: 0;
            }
        }

        &._line &Value {
            &::before {
                position: absolute;
                bottom: 2rem;
                left: 0;
                width: 1rem;
                height: 24rem;
                background: #c8d1dd;
                content: "";

                @include mobile {
                    display: none;
                }
            }
        }
    }

    &._card &__item {
        &._endNumber {
            width: 54rem;

            @include mobile {
                width: 100%;
            }
        }

        &._type {
            width: 160rem;

            @include mobile {
                width: 100%;
            }
        }
    }

    &._account &__items {
        flex-wrap: wrap;
    }

    &._account &__item {
        &._bic {
            // width: 84rem;
        }

        &._addressatName {
            // width: 240rem;
        }

        &._number {
            // width: 240rem;
        }

        &._bankName {
            width: 100%;
        }
    }

    &__endNumber {
        padding: 7rem 8rem 6rem 6rem;
        color: #fff;
        font-size: 13rem;
        white-space: nowrap;
        background: $colorDark;
        border-radius: 7rem;
    }

    &__actions {
        position: absolute;
        right: 26rem;
        align-items: center;
    }

    &__current {
        @include mobile {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            margin-right: 0;
            padding: 8rem 16rem 8rem 20rem;
            color: #fff;
            font-weight: 500;
            font-size: 16rem;
            background: $colorMain;
            border-radius: 22rem 0 20rem;
        }

        &._parent {
            margin-right: 28rem;
        }

        &Loader {
            position: absolute;
            top: 50%;
            right: 100%;
            width: 22rem;
            height: 22rem;
            margin-right: 12rem;
            transform: translate(0, -50%);

            @include loader($colorMain);
        }

        &Inner {
            padding-left: 14rem;
            font-weight: 500;
            font-size: 15rem;
            line-height: 1.3;
            white-space: nowrap;

            &._link {
                padding-left: 0;
                font-weight: 300;
                border-bottom: 1px solid rgba($colorMain, 0.64);

                @include button(0.97);
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                color: $colorMain;
                font-weight: 600;
                font-size: 22rem;
                transform: translate(0, -50%);
                content: "•";
            }

            &._link::before {
                display: none;
            }
        }
    }

    &._card &__actions {
        top: 50%;
        transform: translate(0, -50%);
    }

    &._account &__actions {
        bottom: 23rem;
    }

    &__action {
        width: 16rem;
        height: 16rem;

        @include hover {
            &:hover {
                @include icon($colorAlert);
            }
        }

        @include button(0.95);
        @include icon(rgba(#3e454c, 0.24));
    }

    &__mobActions {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: 20rem;
    }

    &__mobAction {
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 46rem;
        padding: 14rem 12rem;
        background: #fff;
        border-radius: 12rem;

        @include button(0.96);

        &Wrapper {
            width: 100%;

            &._parent:not(._empty) {
                margin-top: 8rem;
            }
        }

        &._delete {
            background: $colorAlertBack;
        }

        &Icon {
            width: 16rem;
            height: 16rem;
            margin-right: 8rem;

            @include icon($colorMain);
        }

        &._delete &Icon {
            @include icon($colorAlert);
        }

        &Content {
            color: $colorMain;
            font-weight: 500;
            font-size: 17rem;
            text-align: center;
        }

        &._delete &Content {
            color: $colorAlert;
        }

        &Loader {
            background: inherit;
            border-radius: inherit;

            @include loader($colorMain);

            & ._LOADERITEM {
                width: 22rem;
                height: 22rem;
            }
        }

        &._delete &Loader {
            @include loader($colorAlert);
        }
    }
}
