$circleCount: 12;
$animationDuration: 1.2s;

.loader {
    position: relative;
    width: 100%;
    height: 100%;
    animation: loaderSpinner 2s infinite ease-in-out;

    @keyframes loaderSpinner {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(360deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &Inner {
            width: 8%;
            min-width: 1px;
            max-width: 2px;
            height: 28%;
            margin: 0 auto;
            background: #fff;
            border-radius: 360rem;
        }
    }

    &__item:nth-child(1) {
        transform: rotate(0deg);
    }

    &__item:nth-child(2) {
        transform: rotate(45deg);
    }

    &__item:nth-child(3) {
        transform: rotate(90deg);
    }

    &__item:nth-child(4) {
        transform: rotate(135deg);
    }

    &__item:nth-child(5) {
        transform: rotate(180deg);
    }

    &__item:nth-child(6) {
        transform: rotate(225deg);
    }

    &__item:nth-child(7) {
        transform: rotate(270deg);
    }

    &__item:nth-child(8) {
        transform: rotate(315deg);
    }
}