.file {
    width: 100%;
    height: 100%;

    &__doc {
        align-items: center;

        &Preview {
            position: relative;
            z-index: 1;
            width: 32rem;
            height: 32rem;
            overflow: hidden;
            background: #fff;
            border-radius: 8rem;

            &Image {
                width: 100%;
                height: 100%;
            }

            &Loader {
                @include loader($colorMain);

                & ._LOADERITEM {
                    width: 18rem;
                    height: 18rem;
                }
            }
        }

        &Content {
            width: calc(100% - 32rem);
            padding-left: 8rem;
        }

        &Name {
            max-width: 100%;
            margin-bottom: 3rem;
            overflow: hidden;
            color: $colorDark;
            font-weight: 500;
            font-size: 14rem;
            line-height: 1.3;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &Size {
            max-width: 100%;
            color: $colorGrey;
            font-size: 13rem;
        }
    }

    &__image {
        display: block;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 50rem;
        max-height: 100%;
        object-fit: cover;
    }
}
