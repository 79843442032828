.profile {
    position: relative;
    width: 100%;
    height: 100%;
    background: #f3f7f9;

    &._admin {
        background: #eef4f7;
    }

    @include mobile {
        background: #eef4f7;
    }

    &__sidebar {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 300rem;
        height: 100%;
    }

    &__info {
        position: absolute;
        top: 20rem;
        left: 20rem;
        z-index: 2;
        width: 426rem;
        height: calc(100% - 40rem);
        overflow: hidden;
        background: #fff;
        border-radius: 28rem;
        box-shadow: 2rem 2rem 24rem rgba($colorDark, 0.06);

        &Logo {
            position: absolute;
            top: 58rem;
            left: 72rem;
            z-index: 2;
            width: 156rem;

            @include button;
        }

        &Pages {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &Page {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 58rem 86rem;

            &:not(._show) {
                &._prev {
                    transform: scale(0.9);
                }

                &._next {
                    transform: scale(1.1);
                }
            }
        }

        &Content {
            margin: auto 0;
            padding-bottom: 50rem;
        }

        &Icon {
            width: 40rem;
            height: 40rem;
            margin-bottom: 14rem;
            margin-left: -1rem;
        }

        &Title {
            margin-bottom: 9rem;
            margin-left: -2rem;
            font-size: 40rem;
            line-height: 1.2;
        }

        &Description {
            font-weight: 300;
            font-size: 16rem;
            line-height: 1.45;
        }

        &Button {
            margin-top: 14rem;
        }

        &Back {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 450rem;
            height: 450rem;
            transform: translate(-25%, 34%) rotate(-24deg);
            pointer-events: none;

            @include icon(rgba($colorMain, 0.08));

            &._company {
                transform: translate(-25%, 25%) rotate(38deg);
            }

            &._orders {
                transform: translate(-15%, 45%) scale(1) rotate(18deg);
            }

            &._wallets {
                transform: translate(-27%, 38%) rotate(-44deg) scale(0.95);
            }

            &._chats {
                transform: translate(-16%, 35%) rotateZ(180deg) scale(0.95);
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__top {
        position: absolute;
        top: 56rem;
        right: 50rem;
        z-index: 3;
        height: 44rem;

        &Button {
            height: 100%;
        }
    }

    &__pages {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__page {
        right: 0;
        left: auto;
        z-index: 2;
        width: calc(100% - 446rem);
        max-width: 1500px;

        &._profileMain {
            @include desktop-l {
                right: auto !important;
                left: calc(calc(100vw - 1500px - 446rem) / 2 + 446rem) !important;
            }
        }

        @include mobile {
            width: 100%;
        }

        &._popup {
            z-index: 1001;
            width: 100%;
        }

        &:not(._show, ._popup, ._end) {
            &._prev {
                transform: translate(-50rem, 0);
            }

            &._next {
                transform: translate(50rem, 0);
            }
        }

        &._full {
            width: 100%;
        }

        &Scroll {
            width: 100%;
            height: 100%;
            padding: 70rem 50rem 20rem;
            overflow: hidden;
            overflow-y: auto;

            @include mobile {
                padding: 90rem 22rem 22rem;
            }

            &._notPad {
                padding: 0;
            }
        }

        &._profile-chats-inner &Scroll {
            @include mobile {
                padding: 80rem 0 0;
            }
        }
    }

    &._admin &__page {
        width: calc(100% - 300rem);

        &._profileMain {
            @include desktop-l {
                right: auto;
                left: calc(calc(100vw - 1500px - 300rem) / 2 + 300rem) !important;
            }
        }

        @include mobile {
            width: 100%;
        }
    }
}
