.input {
    position: relative;
    width: 100%;
    height: 100%;
    background: $colorMainBack;
    border: 1rem solid transparent;
    border-radius: 11rem;
    transition: $trans;

    @include mobile {
        border-radius: 16rem;
    }

    &._emptyBack {
        background: none !important;
        border-radius: 0;
    }

    &._grey {
        background: #f2f7fa;

        @include hover {
            &:not(._disabled):hover {
                background: #eff8ff;
            }
        }

        &._focus {
            background: #eff8ff;
        }
    }

    &._white {
        background: #fff;
    }

    &._chatMessage {
        background: #f3f7f9;
        border: 0;
        border-radius: 12rem;

        @include mobile {
            border-radius: 10rem !important;
        }
    }

    &._error {
        background: $colorAlertBack;
        border-color: rgba($colorAlert, 0.64);
    }

    &__support {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        width: 100%;
        height: 100%;
        color: rgba(#70a6cc, 0.88);
        cursor: text;
    }

    &._error &__support {
        color: rgba($colorAlert, 0.72);
    }

    &._focus &__support,
    &:not(._empty) &__support {
        opacity: 0;
        pointer-events: none;
    }

    &._emptyBack &__support {
        color: #9ab2be;
    }

    &__field,
    &__support {
        align-items: center;
        padding: 12rem 20rem;
        font-size: 16rem;
        line-height: 1.3;
        transition: $trans;

        @include mobile {
            padding: 13rem 16rem;
        }
    }

    &._minHorizPad &__field,
    &._minHorizPad &__support {
        padding-right: 17rem;
        padding-left: 17rem;

        @include mobile {
            padding-right: 22rem;
            padding-left: 22rem;
        }
    }

    &._emptyBack &__field,
    &._emptyBack &__support {
        padding: 0;
    }

    &._chatMessage &__field,
    &._chatMessage &__support {
        padding: 9rem 12rem;
        font-weight: 300;

        @include mobile {
            padding: 10rem 16rem;
            font-size: 16rem;
        }
    }

    &._chatMessage &__support {
        color: $colorGrey;
    }

    &._chatMessage &__field {
        color: $colorDark;
    }

    &._emptyBack._area &__support {
        align-items: flex-start;
    }

    &._grey &__support {
        color: $colorGrey;
    }

    &__field {
        width: 100%;
        height: 100%;
        color: $colorMain;
        resize: none;
    }

    &._area &__support {
        align-items: flex-start;
    }

    &._grey &__field {
        color: $colorDark;
    }

    &._grey._focus &__field {
        color: $colorMain;
    }

    &._emptyBack &__field {
        color: $colorDark;
    }

    &._white &__field {
        color: $colorDark;
    }

    &._error &__field {
        color: $colorAlert;
    }
}
