.profileTable {
    width: 100%;
    height: 100%;

    &__head {
        width: 100%;
        height: 72rem;
        margin-bottom: 1rem;
        padding: 20rem 34rem;
        background: #fff;
        border-radius: 22rem 22rem 2rem 2rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);

        @include mobile {
            height: auto;
            margin-bottom: 18rem;
            padding: 0;
            background: none;
            border-radius: 0;
            box-shadow: none;
        }

        &Inner {
            align-items: center;
            width: 100%;
            height: 100%;
        }

        &Title {
            color: $colorDark;
            font-size: 18rem;

            @include mobile {
                font-size: 18rem;
                line-height: 1.35;
            }

            & b {
                font-weight: 500;
            }

            &Inner {
                font-weight: 300;
                font-size: 18rem;
                line-height: 0.5;
                white-space: nowrap;

                &._parent {
                    display: inline-block;
                }
            }
        }

        &Filter {
            margin-left: 20rem;
        }

        &Actions {
            align-items: center;
            margin-left: auto;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        height: calc(100% - 72rem - 1rem);
        overflow: hidden;
        background: #fff;
        border-radius: 2rem 2rem 22rem 22rem;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);

        @include mobile {
            width: 100%;
            height: auto;
            border-radius: 24rem;
        }

        &Scroll {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }

        &._hidden &Scroll {
            overflow: visible;
        }

        &Inner {
            width: 100%;
            transition: $trans;
        }
    }

    &__content._loading &__row:not(._head) {
        opacity: 0;
    }

    &._logs &__head,
    &._logs &__content {
        box-shadow: none;
    }

    &._logs &__head {
        @include mobile {
            margin-bottom: 0;
        }
    }

    &._logs &__head .filterButton {
        @include mobile {
            background: #f3f7f9;
        }
    }

    &._logs &__content {
        height: auto;
        max-height: 300rem;
    }

    ._parentCalc._logs &__row,
    &._logs &__row {
        @include mobile {
            padding-right: 0;
            padding-left: 0;

            &::after {
                width: 100%;
            }
        }
    }

    &__loader {
        @include loader($colorMain);

        padding-top: 32rem;

        @include mobile {
            padding-top: 0;
        }
    }

    & ._INFOBLOCKWRAPPER {
        padding-top: 32rem;

        @include mobile {
            padding-top: 0;
        }
    }

    &__rows {
        width: 100%;
        transition: $trans;

        &._static {
            display: flex;
            flex-direction: column;
        }
    }

    &__content:not(._ready) &__rows {
        opacity: 0;
    }

    &__row {
        align-items: stretch;
        width: 100%;
        padding: 0 34rem;

        @include mobile {
            padding: 0 20rem;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: calc(100% - 68rem);
            height: 1px;
            background: rgba(#b2b7bf, 0.32);
            transform: translate(-50%, 0);
            opacity: 0;
            content: "";

            @include mobile {
                width: calc(100% - 40rem);
            }
        }

        &._head {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 2;
            background: #fafcfd;
        }

        &:not(._head) {
            padding-top: 28rem;
            padding-bottom: 28rem;
            transition: $trans;

            @include mobile {
                padding-top: 20rem;
                padding-bottom: 20rem;
            }
        }

        &:not(._head, ._last)::after {
            opacity: 1;
        }

        &Block {
            position: relative;
        }
    }

    &__row._head &__col,
    &__rowBlock._head &__col {
        padding-top: 11rem;
        padding-bottom: 11rem;
        color: $colorGrey;
        font-weight: 300;
        font-size: 16rem;

        @include mobile {
            display: inline;
            padding: 0;
            font-weight: 500;
            line-height: 1.4;
        }
    }

    ._parentCalc._operations &__rowBlock,
    &._operations &__rowBlock {
        &._head {
            width: 37%;
            padding-right: 20rem;
        }

        &._content {
            width: 63%;
        }
    }

    ._parentCalc._operations &__col,
    &._operations &__col {
        &._date {
            width: 20%;
        }

        &._description {
            width: 67%;
        }

        &._amount {
            width: 15%;
        }
    }

    ._parentCalc._operations._admin &__col,
    &._operations._admin &__col {
        &._date {
            width: 16%;
        }

        &._holder {
            width: 29%;
        }

        &._description {
            width: 40%;
        }
    }

    ._parentCalc._clients &__rowBlock,
    &._clients &__rowBlock,
    ._parentCalc._workers &__rowBlock,
    &._workers &__rowBlock {
        &._head {
            width: 27%;
            padding-right: 20rem;
        }

        &._content {
            width: 73%;

            @include mobile {
                padding-right: 36rem;
            }
        }
    }

    ._parentCalc._clients &__col,
    &._clients &__col {
        &._name {
            width: 36%;
        }

        &._status {
            width: 17%;
        }

        &._login {
            width: 30%;
        }

        &._orders {
            width: 17%;
        }
    }

    ._parentCalc._workers &__col,
    &._workers &__col {
        &._name {
            width: 36%;
        }

        &._status {
            width: 17%;
        }

        &._login {
            width: 47%;
        }
    }

    ._parentCalc._logs &__rowBlock,
    &._logs &__rowBlock {
        &._head {
            width: 35%;
            padding-right: 20rem;
        }

        &._content {
            width: 65%;
        }
    }

    ._parentCalc._logs &__col,
    &._logs &__col {
        &._name {
            width: 47%;
        }

        &._holder {
            width: 33%;
        }

        &._date {
            width: 20%;
        }
    }

    &__col {
        position: relative;
        padding-right: 20rem;
        font-weight: 300;
        font-size: 16rem;

        @include mobile {
            position: static;
            display: inline;
            width: auto !important;
            padding-right: 0;
        }

        &Text {
            color: $colorDark;
            font-weight: 300;
            font-size: 16rem;
            line-height: 1.4;

            &._inline {
                display: inline;
            }

            @include mobile {
                display: inline;
            }

            &._successColor {
                color: $colorSuccess;
                font-weight: 500;
            }

            &._mediumColor {
                color: #ffb400;
                font-weight: 500;
            }

            &._alertColor {
                color: $colorAlert;
                font-weight: 500;
            }

            &._waitColor {
                color: $colorGrey;
                font-weight: 500;
            }
        }
    }

    ._parentCalc._logs &__colText,
    &._logs &__colText {
        & b {
            padding: 0 4rem;
            background: #f3f7f9;
            border-radius: 5rem;
        }
    }

    &__holder {
        position: relative;

        @include mobile {
            flex-direction: column;
        }

        &Avatar {
            position: absolute;
            top: 50%;
            left: 0;
            width: 44rem;
            height: 44rem;
            transform: translate(0, -50%);

            @include mobile {
                position: relative;
                top: 0;
                margin-bottom: 8rem;
                transform: translate(0, 0);
            }
        }

        &._mediumSize &Avatar {
            width: 40rem;
            height: 40rem;

            & .avatar__text {
                font-size: 15rem;
            }
        }

        &Content {
            padding-left: 56rem;

            @include mobile {
                padding-left: 0;
            }
        }

        &._mediumSize &Content {
            padding-left: 48rem;
        }
    }

    &__holderContent &__colText {
        @include mobile {
            display: block;
        }
    }

    &__more {
        position: absolute;
        top: -2rem;
        right: 0;
        z-index: 2;
        width: 26rem;
        height: 26rem;

        @include mobile {
            top: -4rem;
            right: -2rem;
            width: 28rem;
            height: 28rem;
        }
    }
}
