.profileSidebar {
    width: 100%;
    height: 100%;
    background: #fff;

    &__head {
        justify-content: flex-end;
        width: 100%;
        padding: 64rem 40rem 20rem;

        &Logo {
            align-items: center;
            width: 100%;

            &Image {
                display: block;
                width: 156rem;
            }

            &Title {
                width: 56rem;
                margin-left: 9rem;
            }
        }
    }

    &__content {
        width: 100%;
        padding: 0 30rem;
    }

    &__nav {
        width: 100%;
        padding: 0 20rem;

        &Item {
            width: 100%;
            padding: 26rem 0;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(#b8c3ce, 0.4);
            }

            &Link {
                position: relative;
                padding-left: 32rem;

                @include button(0.96);

                @include hover {
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            &Counter {
                position: absolute;
                bottom: 100%;
                left: 100%;
                z-index: 2;
                margin: 0 0 -6rem 3rem;
                pointer-events: none;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 17rem;
                height: 17rem;
                transform: translate(0, -50%);

                @include icon(#b0bbc8);
            }

            &Content {
                position: relative;
                font-weight: 300;
                font-size: 18rem;
                white-space: nowrap;
                transition: $trans;

                &Bold {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $colorMain;
                    font-weight: 500;
                    font-size: 18rem;
                    letter-spacing: -0.06rem;
                    white-space: nowrap;
                    opacity: 0;
                    transition: $trans;
                }
            }

            &Link._current {
                @include icon($colorMain);
            }

            &Link._current &Content {
                color: #fff;

                &Bold {
                    opacity: 1;
                }
            }
        }
    }

    &__foot {
        width: 100%;
        margin-top: auto;
        padding: 36rem 30rem;

        &Button {
            width: 100%;
            height: 52rem;

            & + & {
                margin-top: 8rem;
            }
        }
    }
}
