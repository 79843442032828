.publicBlogPreview {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 40rem 46rem;
    overflow: hidden;
    border-radius: 32rem;
    transition: $trans;

    @include button(0.98);

    @include mobile {
        padding: 20rem;
        border-radius: 24rem;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(#000, 0.88), rgba(#000, 0.32));
        content: "";
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    &__head {
        align-items: center;
        width: 100%;
        margin-bottom: auto;

        @include mobile {
            margin-bottom: 32rem;
        }

        &Info {
            align-items: center;
            height: 36rem;
            padding: 5rem 16rem;
            color: rgba(#fff, 0.8);
            font-size: 17rem;
            background: rgba(#000, 0.64);
            border-radius: 360rem;

            @include mobile {
                height: 36rem;
                padding: 6rem 14rem;
                font-size: 16rem;
            }

            &._section {
                color: #fff;
            }

            &._views {
                margin-left: auto;
            }

            &Icon {
                width: 20rem;
                height: 20rem;
                margin-right: 7rem;

                @include mobile {
                    width: 18rem;
                    height: 18rem;
                    margin-right: 8rem;
                }
            }
        }
    }

    &__title {
        width: 600rem;
        max-width: 100%;
        margin-bottom: 20rem;
        color: #fff;
        font-weight: 500;
        font-size: 28rem;
        line-height: 1.3;

        @include mobile {
            min-height: 120rem;
            margin-bottom: 32rem;
            padding: 0;
            font-size: 22rem;
        }
    }

    &._main &__title {
        margin-bottom: 32rem;
        font-size: 32rem;

        @include mobile {
            margin-bottom: 32rem;
            font-size: 22rem;
        }
    }

    &__foot {
        align-items: center;
        width: 100%;
        padding-bottom: 8rem;

        @include mobile {
            padding: 0 1.5rem 1rem;
        }

        &Time {
            align-items: center;
            color: #cbff5d;
            font-size: 16rem;

            @include mobile {
                font-size: 16rem;
            }

            &Icon {
                width: 16rem;
                height: 16rem;
                margin-right: 8rem;

                @include mobile {
                    width: 16rem;
                    height: 16rem;
                }
            }
        }

        &Date {
            margin-left: auto;
            color: #fff;
            font-weight: 500;
            font-size: 16rem;

            @include mobile {
                font-size: 16rem;
            }
        }
    }
}
