.lazyImage {
    position: relative;
    width: 100%;
    height: 100%;

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24rem;
        height: 24rem;
        transform: translate(-50%, -50%);

        @include loader($colorMain);

        @include mobile {
            width: 26rem;
            height: 26rem;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: $trans;
    }

    &:not(._ready) &__image {
        opacity: 0;
    }
}
