.orderCargo {
    width: 100%;

    &__content {
        width: 100%;

        &Head,
        &Items {
            position: relative;
            z-index: 2;
            width: 100%;
            padding: 26rem 28rem;
            background: #fff;
            border-radius: 16rem 16rem 2rem 2rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);
            transition: $trans;

            @include mobile {
                margin-bottom: 12rem;
                padding: 6rem;
                border-radius: 18rem;
            }
        }

        &._empty &Head {
            border-radius: 16rem;

            @include mobile {
                margin-bottom: 0;
                border-radius: 24rem;
            }
        }

        &._empty &Items {
            padding-top: 0;
            padding-bottom: 0;
            box-shadow: none;
            transform: translate(0, -16rem);

            @include mobile {
                transform: translate(0, -24rem);
            }
        }

        &Items {
            position: relative;
            z-index: 1;
            width: 100%;
            margin-top: 2rem;
            border-radius: 2rem 2rem 16rem 16rem;

            @include mobile {
                padding: 0;
                background: none;
                border-radius: 0;
                box-shadow: none;
            }
        }
    }

    &__search {
        position: relative;
        width: 100%;

        @include mobile {
            height: 52rem;
        }

        & .input__support,
        & .input__field {
            padding-right: 60rem !important;

            @include mobile {
                padding-right: 76rem !important;
                padding-left: 14rem !important;
            }
        }

        &Button {
            position: absolute;
            top: 50%;
            right: 12rem;
            z-index: 2;
            width: 26rem;
            height: 26rem;
            background: $colorMain;
            border-radius: 8rem;
            transform: translate(0, -50%);

            @include mobile {
                right: 13rem;
                width: 26rem;
                height: 26rem;
            }

            @include hover {
                &:hover {
                    background: $colorMain2;
                }
            }

            @include button(0.95, true);

            &Icon {
                display: block;
                width: 14rem;
                height: 14rem;
                margin: auto;

                @include mobile {
                    width: 14rem;
                    height: 14rem;
                }

                @include icon(#fff);
            }
        }
    }

    &__items {
        width: 100%;
        transition: $trans;
    }

    &__item {
        width: 100%;
    }

    &__items._static &__item {
        &:not(._last) {
            margin-bottom: 8rem;

            @include mobile {
                margin-bottom: 4rem;
            }
        }
    }

    &__item:not(._show) &__cargo {
        opacity: 0;
    }

    &__cargo {
        position: relative;
        width: 100%;
        height: 38rem;
        padding-right: 44rem;
        transition: $trans;

        @include mobile {
            height: auto;
            padding-right: 0;
            border-radius: 24rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);
        }

        &Check {
            position: absolute;
            top: 50%;
            left: 10rem;
            z-index: 2;
            width: 16rem;
            height: 16rem;
            transform: translate(0, -50%);

            @include mobile {
                top: 14rem;
                left: 14rem;
                width: 20rem;
                height: 20rem;
                transform: translate(0, 0);
            }
        }

        &Fields {
            align-items: center;
            width: 100%;
            height: 100%;

            @include mobile {
                flex-wrap: wrap;
                height: auto;
            }
        }

        &._check &Field._name {
            padding-left: 34rem;

            @include mobile {
                padding-left: 40rem;
            }
        }

        &Field {
            width: 5%;
            height: 100%;
            padding: 0 12rem;
            background: #f1f9ff;
            border-radius: 2rem;
            transition: $trans;

            @include mobile {
                height: 48rem;
                margin-bottom: 2rem;
                padding: 0 8rem;
                background: #fff;
                border-radius: 0;
            }

            &._name {
                width: 45%;
                margin-right: 3rem;
                border-top-left-radius: 10rem;
                border-bottom-left-radius: 10rem;

                @include mobile {
                    order: 1;
                    width: 67%;
                    margin-right: 2rem;
                    padding-right: 72rem;
                    border-radius: 16rem 0 2rem 2rem;
                }
            }

            &._long,
            &._width,
            &._height,
            &._weight,
            &._volume {
                width: calc(55% / 5);
                margin-right: 2rem;

                @include mobile {
                    order: 4;
                    width: calc(calc(100% / 3) - 2rem);
                    margin-right: 0;
                }
            }

            &._weight {
                @include mobile {
                    order: 9;
                    width: calc(25% - 6rem);
                    border-radius: 2rem 2rem 16rem;
                }
            }

            &._volume {
                margin-right: 0;
                border-top-right-radius: 10rem;
                border-bottom-right-radius: 10rem;

                @include mobile {
                    order: 2;
                    width: calc(33% - 2rem);
                    border-radius: 0 16rem 0 0;
                }
            }

            &._long {
                @include mobile {
                    width: 25%;
                    margin-right: 2rem;
                    border-radius: 2rem 2rem 2rem 16rem;
                }
            }

            &._width {
                @include mobile {
                    width: 25%;
                    margin-right: 2rem;
                    border-radius: 2rem;
                }
            }

            &._height {
                @include mobile {
                    width: 25%;
                    margin-right: 2rem;
                    border-radius: 2rem;
                }
            }

            & .input__field,
            & .input__support {
                font-weight: 300;
                font-size: 14rem;

                @include mobile {
                    padding-right: 4rem !important;
                    padding-left: 4rem !important;
                    font-size: 16rem;
                }
            }
        }

        &Delete {
            position: absolute;
            top: 50%;
            right: 0;
            width: 38rem;
            height: 100%;
            background: $colorAlertBack;
            border-radius: 11rem;
            transform: translate(0, -50%);

            @include mobile {
                top: 8rem;
                right: 8rem;
                width: 32rem;
                height: 32rem;
                border-radius: 10rem;
                transform: translate(0, 0);
            }

            @include button(0.95, true);

            &Icon {
                display: block;
                width: 12rem;
                height: 12rem;
                margin: auto;

                @include mobile {
                    width: 14rem;
                    height: 14rem;
                }

                @include icon($colorAlert);
            }

            @include hover {
                &:hover {
                    background: rgba($colorAlert, 0.2);
                }
            }
        }
    }
}
