.notifyCounter {
    height: 20rem;
    padding: 5rem;
    background: $colorAlert;
    border-radius: 7rem;

    @include mobile {
        height: 24rem;
        border-radius: 8rem;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 10rem;
        height: 100%;
        color: #fff;
        font-weight: 600;
        font-size: 13rem;
        white-space: nowrap;
        text-align: center;

        @include mobile {
            min-width: 14rem;
            font-size: 14rem;
        }

        &._child {
            padding-top: 1rem;
        }
    }

    ._parentCalc._mediumSize,
    &._mediumSize {
        height: 16rem;
        padding: 4rem;
        border-radius: 6rem;

        @include mobile {
            height: 20rem;
            border-radius: 7rem;
        }
    }

    ._parentCalc._mediumSize &__content,
    &._mediumSize &__content {
        min-width: 8rem;
        font-size: 12rem;

        @include mobile {
            min-width: 12rem;
            font-size: 14rem;
        }
    }

    ._parentCalc._minSize,
    &._minSize {
        height: 12rem;
        padding: 3rem;
        border-radius: 5rem;

        @include mobile {
            height: 16rem;
            border-radius: 6rem;
        }
    }

    ._parentCalc._minSize &__content,
    &._minSize &__content {
        min-width: 6rem;
        font-size: 10rem;

        @include mobile {
            min-width: 10rem;
            font-size: 12rem;
        }
    }
}
