.orderTariffs {
    width: 100%;

    &__content {
        width: 100%;

        @include mobile {
            width: calc(100% + 40rem);
            margin-left: -20rem;
            padding: 0 20rem 12rem;
            overflow-x: auto;
        }
    }

    &__cards {
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + 12rem);
        margin-bottom: -12rem;
        margin-left: -6rem;

        @include mobile {
            width: calc(100% + 8rem);
            margin-bottom: 0;
            margin-left: -4rem;
        }
    }

    &._out &__cards {
        margin-bottom: 0;
    }

    &._out &__card {
        width: 20%;
        margin-bottom: 0;

        @include mobile {
            width: 50%;
            margin-bottom: 10rem;
        }
    }

    &__card {
        position: relative;
        width: 25%;
        margin-bottom: 12rem;
        padding: 0 6rem;
        transition: $trans;

        @include mobile {
            width: 50%;
            margin-bottom: 8rem;
            padding: 0 4rem;

            & + & {
                // margin-left: 6rem;
            }
        }

        @include hover {
            &:not(._info):hover {
                transform: translate(0, -4rem);
            }
        }

        &._info {
            flex: 1 auto;
        }

        &Info {
            width: 100%;
            height: 100%;
            background: rgba(#fff, 0.56);
            border-radius: 13rem;
        }
    }

    &._out &__tariff {
        &Top,
        &Foot {
            box-shadow: 0 2rem 8rem rgba(#000, 0.04);
        }

        &Foot {
            z-index: 2;
        }
    }

    &__tariff {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        transition: $trans;

        @include button(0.97);

        &._disabled {
            opacity: 0.8;
        }

        &._inactive {
            opacity: 0.64;
        }

        &Weight,
        &Dis {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 52rem;
            padding: 5rem 7rem 4rem;
            color: #fff;
            font-weight: 600;
            font-size: 13rem;
            text-align: center;
            background: #3e454c;
            border-radius: 0 0 13rem;
            transition: $trans;

            @include mobile {
                width: 56rem;
                padding: 6rem;
                font-size: 14rem;
                border-radius: 0 0 14rem;
            }
        }

        &Dis {
            left: 40rem;
            z-index: 2;
            width: auto;
            padding-right: 10rem;
            padding-left: 18rem;
            background: #f29438;

            // font-weight: 500;
            @include mobile {
                padding-left: 22rem;
            }
        }

        @include hover {
            &:not(._disabled):hover &Weight {
                background: $colorMain;
            }
        }

        &._current &Weight {
            background: $colorMain;
        }

        &Top {
            position: relative;
            z-index: 1;
            flex: 1 auto;
            width: 100%;
            margin-bottom: 1rem;
            overflow: hidden;
            background: #fff;
            border-radius: 14rem 14rem 2rem 2rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);

            @include mobile {
                border-radius: 20rem 20rem 2rem 2rem;
            }
        }

        &Check {
            position: absolute;
            top: 13rem;
            right: 13rem;
            z-index: 2;
            display: flex;
            flex-direction: column;
            width: 18rem;
            height: 18rem;
            background: #f3f7f9;
            border-radius: 6rem;
            transition: $trans;

            @include mobile {
                top: 10rem;
                right: 10rem;
                width: 20rem;
                height: 20rem;
                border-radius: 7rem;
            }

            &Icon {
                display: block;
                width: 10rem;
                height: 10rem;
                margin: auto;
                opacity: 0;
                transition: $trans;

                @include mobile {
                    width: 12rem;
                    height: 12rem;
                }

                @include icon(#fff);
            }
        }

        &._current &Check {
            background: $colorMain;

            &Icon {
                opacity: 1;
            }
        }

        &Head {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 136rem;
            margin-bottom: -4rem;
            padding-top: 20rem;

            @include mobile {
                height: 90rem;
                margin-bottom: 0;
                padding-top: 0;
            }
        }

        &Image {
            width: 100%;
            pointer-events: none;
        }

        &Content {
            position: relative;
            z-index: 2;
            width: 100%;
            height: calc(100% - 136rem);
            padding: 0 24rem 16rem;

            @include mobile {
                height: calc(100% - 90rem);
                padding: 0 14rem 14rem;
            }
        }

        &Name {
            margin-bottom: 6rem;
            margin-left: -1rem;
            font-size: 18rem;

            @include mobile {
                margin-bottom: 8rem;
                font-weight: 500;
                font-size: 16rem;
                line-height: 1.2;
            }
        }

        &Disabled {
            width: calc(100% + 24rem);
            margin-top: auto;
            margin-bottom: -8rem;
            margin-left: -12rem;
            padding: 12rem;
            background: rgba(#f29438, 0.1);
            border-radius: 14rem;

            &Title {
                max-width: 100%;
                margin-top: auto;
                margin-bottom: 4rem;
                font-size: 15rem;
            }
        }

        &Description {
            max-width: 100%;
            font-weight: 300;
            font-size: 12rem;
            line-height: 1.35;

            @include mobile {
                display: none;
                font-size: 16rem;
            }

            & b {
                font-weight: 500;
            }
        }

        &Foot {
            width: 100%;
            padding: 16rem 24rem;
            background: #fff;
            border-radius: 2rem 2rem 14rem 14rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.02);

            @include mobile {
                padding: 12rem 16rem 14rem;
                border-radius: 2rem 2rem 20rem 20rem;
            }

            &Items {
                width: 100%;
            }

            &Item {
                position: relative;
                padding-left: 24rem;
                color: $colorDark;
                font-size: 16rem;

                @include mobile {
                    padding-left: 18rem;
                    font-size: 14rem;
                }

                &._volume {
                    @include mobile {
                        padding-left: 23rem;
                    }
                }

                & + & {
                    margin-left: 14rem;

                    @include mobile {
                        margin-left: 8rem;
                    }
                }

                &Icon {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 18rem;
                    height: 18rem;
                    margin-top: -2rem;
                    transform: translate(0, -50%);

                    @include mobile {
                        width: 14rem;
                        height: 14rem;
                    }
                }

                &._volume &Icon {
                    width: 22rem;
                    height: 22rem;

                    @include mobile {
                        width: 20rem;
                        height: 20rem;
                    }
                }
            }
        }
    }
}
