.emodziList {
    width: 100%;
    height: 100%;
    max-height: 210rem;
    padding: 10rem;
    overflow-y: auto;
    background: #f5f9fa;
    border-radius: 20rem;

    @include mobile {
        max-height: 240rem;
        padding: 16rem;
        background: none;
        border-radius: 0;
    }

    &__items {
        flex-wrap: wrap;
        width: calc(100% + 4rem);
        margin-bottom: -4rem;
        margin-left: -2rem;

        @include mobile {
            width: calc(100% + 6rem);
            margin-bottom: -3rem;
            margin-left: -3rem;
        }
    }

    &__item {
        width: calc(100% / 6);
        margin-bottom: 4rem;
        padding: 0 2rem;

        @include mobile {
            width: calc(100% / 7);
            margin-bottom: 6rem;
            padding: 0 3rem;
        }

        &Inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 34rem;
            padding-top: 4rem;
            font-size: 22rem;
            text-align: center;
            background: rgba($colorDark, 0.06);
            border-radius: 10rem;

            @include mobile {
                height: 44rem;
                font-size: 24rem;
            }

            @include button(0.93);
        }
    }
}
