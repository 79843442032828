.profileChatsMessage {
    position: relative;
    width: 100%;
    padding: 2rem 44rem 2rem 0;

    @include mobile {
        padding-right: 36rem;
    }

    &._typeMe {
        align-items: flex-end;
    }

    &__inner {
        position: relative;
        max-width: 500rem;

        @include mobile {
            max-width: 260rem;
        }

        &::after {
            position: absolute;
            right: 100%;
            bottom: 6rem;
            z-index: 2;
            width: 4rem;
            height: 4rem;
            margin-right: 6rem;
            background: $colorAlert;
            border-radius: 50%;
            opacity: 0;
            transition: $trans;
            content: "";
            pointer-events: none;
        }
    }

    &[data-notread] &__inner::after {
        opacity: 1;
    }

    &__content {
        width: 100%;
        background: #f3f7f9;
        border-radius: 14rem 14rem 14rem 2rem;

        &Name {
            margin-bottom: 3rem;
            padding: 12rem 14rem 0;
            font-weight: 400;
            font-size: 16rem;
            line-height: 1.3;

            @include mobile {
                // font-size: 15rem;
            }
        }

        &Text {
            padding: 0 14rem 12rem;
            font-weight: 300;
            font-size: 15rem;
            line-height: 1.35;

            @include mobile {
                font-size: 16rem;
            }
        }
    }

    &._file &__content {
        position: relative;
        z-index: 1;
        overflow: hidden;

        &Name {
            margin-bottom: 0;
            padding-bottom: 9rem;
        }
    }

    &._typeMe &__content {
        background: #f1f9ff;
        border-color: #f1f9ff;
        border-radius: 14rem 14rem 2rem;

        &Text,
        &Name {
            // color: #fff;
        }
    }

    &__info {
        position: absolute;
        top: 8rem;
        right: 0;
    }

    &__time {
        color: $colorGrey;
        font-weight: 300;
        font-size: 13rem;

        @include mobile {
            font-size: 12rem;
        }
    }

    &__read {
        width: 14rem;
        margin: 6rem 0 0 auto;

        &:not(._complete) {
            @include icon($colorGrey);
        }
    }
}
