.orderDetails {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 200rem;
        background: linear-gradient(to bottom, #fff, #fff 30%, rgba(#fff, 0));
        content: "";
        pointer-events: none;

        @include mobile {
            display: none;
        }
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        height: 300rem;
        background: linear-gradient(to bottom, #fff, rgba(#fff, 0));
        transition: $trans;
        content: "";
        pointer-events: none;

        @include mobile {
            display: none;
        }
    }

    &:not(._scrollActive)::after {
        opacity: 0;
    }

    &__loader {
        @include loader($colorMain);
    }

    &__inner {
        position: relative;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-bottom: 40rem;
        overflow: hidden;
        overflow-y: auto;

        @include mobile {
            padding-bottom: 22rem;
        }
    }

    &:not(._ready) &__content {
        opacity: 0;
    }

    &__content {
        position: relative;
        z-index: 3;
        width: 870rem;
        padding-top: 100rem;
        transition: $trans;

        @include mobile {
            width: 100%;
            padding: 112rem 12rem 0;
        }
    }

    &__status {
        display: inline-block;
        height: 18rem;
        margin-left: 10rem;

        @include mobile {
            height: 26rem;
            margin-left: 0;
        }

        &Inner {
            color: #fff;
            font-size: 14rem;
            white-space: nowrap;

            @include mobile {
                font-size: 18rem;
            }

            &._parent {
                border-radius: 8rem;

                @include mobile {
                    border-radius: 0 0 0 20rem;
                }
            }

            &._child {
                padding: 5rem 10rem 5rem 20rem;

                @include mobile {
                    padding: 6rem 20rem 6rem 16rem;
                }

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 9rem;
                    color: #fff;
                    font-weight: 600;
                    font-size: 16rem;
                    transform: translate(0, -50%);
                    content: "•";

                    @include mobile {
                        left: 16rem;
                        display: none;
                        font-size: 22rem;
                    }
                }
            }
        }

        @each $status, $color in $orderStatuses {
            &._#{$status} &Inner._parent {
                background: $color;
            }
        }
    }

    &:not(._ready) &__head {
        opacity: 0;
    }

    &__head {
        position: absolute;
        top: 40rem;
        z-index: 10;
        align-items: center;
        width: 100%;
        height: 44rem;
        transition: $trans;

        @include mobile {
            position: relative;
            top: 0;
            height: auto;
            margin-bottom: 24rem;
            padding: 0 14rem;
        }

        &Inner {
            align-items: center;
            width: 870rem;
            height: 100%;

            @include mobile {
                flex-direction: column;
                width: 100%;
            }
        }

        &Title {
            font-size: 20rem;

            @include mobile {
                margin-bottom: 24rem;
                font-size: 20rem;
                text-align: center;
            }

            & span {
                font-weight: 300;
                font-size: 20rem;

                @include mobile {
                    font-size: 20rem;
                }
            }
        }

        &Actions {
            height: 100%;
            margin-left: auto;

            @include mobile {
                width: 100%;
                height: 56rem;
            }
        }

        &Button {
            align-items: center;
            height: 100%;
            border-radius: 12rem;

            @include mobile {
                border-radius: 16rem;
            }

            @include button(0.96);

            & + & {
                margin-left: 12rem;
            }

            &._repeat {
                width: 44rem;
                background: #ecf7ff;

                @include mobile {
                    width: 56rem;
                    background: #fff;
                }
            }

            &._cancel {
                padding: 10rem 24rem;
                color: $colorAlert;
                font-size: 16rem;
                background: #ffede9;

                @include mobile {
                    justify-content: center;
                    width: calc(100% - 56rem - 10rem);
                    font-size: 18rem;
                    text-align: center;
                }

                // border: 1rem solid $colorAlert;

                @include loader($colorAlert);
            }

            &Icon {
                display: block;
                width: 20rem;
                height: 20rem;
                margin: auto;

                @include icon($colorMain);

                @include mobile {
                    width: 26rem;
                    height: 26rem;
                }
            }

            &Loader {
                background: inherit;
                border-radius: inherit;

                & ._LOADERITEM {
                    width: 22rem;
                    height: 22rem;
                }
            }
        }
    }

    &__blocks {
        position: relative;
        z-index: 3;
        width: 100%;
    }

    &__block {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 24rem 32rem 32rem;
        overflow: hidden;
        background: rgba(#fff, 0.2);
        border-radius: 28rem;
        box-shadow: 0 2rem 100rem rgba(#363c42, 0.16);

        @include mobile {
            padding: 28rem 20rem 20rem;
            border-radius: 26rem;
        }

        &._route {
            @include mobile {
                padding-top: 40rem;
            }
        }

        &._tariff {
            margin-top: 140rem;
        }

        & + & {
            margin-top: 50rem;

            @include mobile {
                margin-top: 24rem;
            }
        }

        &Status {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
        }

        &Title {
            position: relative;
            max-width: 100%;
            margin-bottom: 20rem;
            padding-left: 23rem;
            color: $colorDark;
            font-size: 19rem;

            @include mobile {
                margin-bottom: 24rem;
                margin-left: 6rem;
                padding-left: 30rem;
                font-size: 20rem;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 14rem;
                height: 14rem;
                transform: translate(0, -50%);

                @include mobile {
                    width: 20rem;
                    height: 20rem;
                }

                @include icon($colorMain);
            }
        }
    }

    &__options {
        width: 100%;
    }

    &__option {
        align-items: stretch;
        width: 100%;

        @include mobile {
            flex-wrap: wrap;
        }

        & + & {
            margin-top: 12rem;
        }

        &Block {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 38rem;
            background: #fff;
            border-radius: 2rem;
            box-shadow: 0 4rem 8rem rgba(#000, 0.03);

            @include mobile {
                z-index: 1;
                width: 100%;
                padding: 20rem !important;
                overflow: hidden;
            }

            &:not(:first-child) {
                margin-left: 2rem;

                @include mobile {
                    margin-left: 0;
                }
            }

            &:first-child {
                border-top-left-radius: 20rem;
                border-bottom-left-radius: 20rem;

                @include mobile {
                    margin-bottom: 2rem;
                    border-radius: 24rem 24rem 2rem 2rem;
                }
            }

            &:last-child {
                border-top-right-radius: 20rem;
                border-bottom-right-radius: 20rem;

                @include mobile {
                    border-radius: 2rem 2rem 24rem 24rem;
                }
            }
        }

        &._tariff &Block {
            &._main {
                width: calc(100% - 170rem - 150rem);
                padding-right: 230rem;

                @include mobile {
                    width: 100%;
                    margin-bottom: 0;
                    padding-right: 0;
                }
            }

            &._weight,
            &._volume {
                padding: 30rem;
            }

            &._weight {
                width: 170rem;

                @include mobile {
                    width: calc(50% - 1rem);
                    margin-top: 2rem;
                    border-radius: 0 0 0 32rem;
                }
            }

            &._volume {
                width: 150rem;

                @include mobile {
                    width: calc(50% - 1rem);
                    margin-top: 2rem;
                    margin-left: 2rem;
                    border-radius: 0 0 32rem;
                }
            }
        }

        &._service &Block {
            &._main {
                width: calc(100% - 150rem);
                padding: 24rem 38rem;

                @include mobile {
                    width: 100%;
                }
            }

            &._value {
                width: 150rem;
                padding: 24rem;

                @include mobile {
                    width: 100%;
                }
            }
        }

        &Short {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            min-width: 50rem;
            padding: 5rem 8rem;
            color: #fff;
            font-weight: 600;
            font-size: 13rem;
            text-align: center;
            background: $colorMain;
            border-radius: 16rem 0 14rem;

            @include mobile {
                padding: 6rem 16rem 6rem 20rem;
                font-size: 18rem;
                border-radius: 20rem 0;
            }
        }

        &Icon {
            display: block;
            width: 22rem;
            height: 22rem;
            margin-bottom: 8rem;

            @include mobile {
                width: 30rem;
                height: 30rem;
                margin-bottom: 14rem;
            }
        }

        &Block._volume &Icon {
            transform: scale(1.3);

            @include mobile {
                transform-origin: left center;
            }
        }

        &Title {
            position: relative;
            z-index: 3;
            margin-bottom: 8rem;
            font-size: 21rem;

            @include mobile {
                font-size: 20rem;
            }

            &._big {
                margin-bottom: 2rem;
                font-size: 25rem;

                @include mobile {
                    font-size: 20rem;
                }
            }
        }

        &Description {
            position: relative;
            z-index: 3;
            font-weight: 300;
            font-size: 13rem;
            line-height: 1.3;

            @include mobile {
                font-size: 16rem;
            }
        }

        &Block._weight &Description,
        &Block._volume &Description {
            @include mobile {
                font-size: 14rem;
            }
        }

        &Tariff {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 2;
            width: 230rem;
            margin-top: -10rem;
            transform: translate(0, -50%);

            @include mobile {
                position: relative;
                top: 0;
                z-index: 1;
                width: calc(100% + 40rem);
                margin-top: 0;
                margin-top: -20rem;
                margin-left: -20rem;
                transform: translate(0, 0);
            }

            &._courier::before {
                position: absolute;
                top: 50%;
                left: -2rem;
                z-index: 2;
                width: 100rem;
                height: 100%;
                background: linear-gradient(to right, #fff, #fff 10rem, rgba(#fff, 0));
                transform: translate(0, -50%);
                content: "";

                @include mobile {
                    display: none;
                }
            }

            &Image {
                width: 100%;
                object-fit: contain;

                @include mobile {
                    height: 130rem;
                    object-position: left center;
                }
            }
        }

        &Service {
            padding: 14rem 10rem;
            font-weight: 300;
            font-size: 16rem;
            text-align: center;
            background: #f1f9ff;
            border-radius: 14rem;

            @include mobile {
                padding: 14rem 20rem;
                font-weight: 500;
                font-size: 22rem;
                border-radius: 16rem;
            }
        }

        &Switch {
            align-items: center;
            color: $colorGrey;
            font-weight: 300;
            font-size: 14rem;

            @include mobile {
                font-size: 18rem;
            }

            &._active {
                color: $colorMain;
            }

            &Box {
                margin-right: 9rem;

                @include mobile {
                    margin-right: 12rem;
                }
            }
        }
    }

    &__markets {
        width: calc(100% + 64rem);
        margin-bottom: 32rem;
        margin-left: -32rem;
        padding: 0 32rem;
        overflow-x: auto;

        @include mobile {
            width: 100%;
            margin-bottom: 12rem;
            margin-left: 0;
            padding: 0;
            overflow: visible;
        }

        &Inner {
            display: inline-flex;

            @include mobile {
                display: flex;
                flex-flow: row wrap;
                align-items: stretch;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    &__market {
        position: relative;
        width: 190rem;
        padding: 32rem 24rem 24rem;
        background: #fff;
        border: 1px solid transparent;
        border-radius: 16rem;
        box-shadow: 0 0 2rem rgba(#000, 0.07);

        @include mobile {
            display: flex;
            flex-direction: column;
            width: calc(50% - 3rem);
            margin-bottom: 6rem;
            padding: 20rem;
            border-radius: 20rem;
        }

        &._current {
            border-color: $colorMain;

            @include mobile {
                padding-top: 32rem;
            }
        }

        & + & {
            margin-left: 10rem;

            @include mobile {
                margin-left: 0;
            }
        }

        &Current {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            padding: 4rem 12rem 5rem 11rem;
            color: #fff;
            font-weight: 600;
            font-size: 12.5rem;
            background: $colorMain;
            border-radius: 14rem 0 10rem;

            @include mobile {
                padding: 5rem 13rem;
                font-size: 15rem;
                border-radius: 20rem 0 16rem;
            }
        }

        &Logo {
            width: 100%;
            height: 30rem;
            margin-bottom: 6rem;
            object-fit: contain;
            object-position: left center;

            @include mobile {
                height: 32rem;
                margin-top: auto;
                margin-bottom: 10rem;
            }
        }

        &Foot {
            align-items: center;
            width: 100%;
        }

        &Price {
            font-size: 23rem;

            @include mobile {
                font-size: 22rem;
            }
        }

        &._current &Price {
            color: $colorMain;
        }
    }

    &__crew {
        width: 100%;

        &Head,
        &Content {
            width: 100%;
            padding: 20rem 32rem;
            background: #fff;
            box-shadow: 0 2rem 8rem rgba(#000, 0.03);

            @include mobile {
                padding: 18rem 20rem;
            }
        }

        &Head {
            margin-bottom: 2rem;
            border-radius: 16rem 16rem 2rem 2rem;

            @include mobile {
                display: flex;
                align-items: center;
                height: 70rem;
                border-radius: 24rem 24rem 2rem 2rem;
            }
        }

        &Content {
            align-items: stretch;
            border-radius: 2rem 2rem 16rem 16rem;

            @include mobile {
                flex-direction: column;
                border-radius: 2rem 2rem 24rem 24rem;
            }
        }

        &Title {
            font-size: 18rem;

            @include mobile {
                font-size: 18rem;
            }

            & span {
                font-weight: 300;
                font-size: 18rem;

                @include mobile {
                    font-size: 18rem;
                }
            }
        }

        &Block {
            position: relative;

            @include mobile {
                padding-left: 64rem !important;
            }

            & + & {
                margin-left: 18rem;
                padding-left: 18rem;
                border-left: 1px solid #c8d1dd;

                @include mobile {
                    margin-top: 20rem;
                    margin-left: 0;
                    padding-left: 0;
                    border-left: 0;
                }
            }

            &._name {
                min-width: 240rem;
                padding-left: 60rem;
            }

            &Preview {
                position: absolute;
                top: 50%;
                left: 0;
                display: flex;
                width: 48rem;
                height: 48rem;
                background: #f2f7fa;
                border-radius: 50%;
                transform: translate(0, -50%);

                @include mobile {
                    top: -2rem;
                    width: 50rem;
                    height: 50rem;
                    transform: translate(0, 0);
                }

                &Icon {
                    width: 20rem;
                    height: 20rem;
                    margin: auto;

                    @include mobile {
                        width: 24rem;
                        height: 24rem;
                    }
                }
            }

            &Support {
                margin-bottom: 6rem;
                font-weight: 300;
                font-size: 15rem;

                @include mobile {
                    font-size: 18rem;
                }
            }

            &Value {
                font-size: 16rem;

                @include mobile {
                    font-size: 18rem;
                    line-height: 1.3;
                }
            }
        }
    }
}
