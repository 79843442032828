.publicContacts {
    width: 100%;

    &__inner {
        align-items: center;
    }

    &__logo {
        margin-bottom: 62rem;
    }

    &__head {
        align-items: center;
        margin-bottom: 34rem;

        @include mobile {
            margin-bottom: 26rem;
        }
    }

    &__title {
        margin-bottom: 10rem;
        color: $colorDark;
        font-weight: 500;
        font-size: 40rem;
        text-align: center;

        @include mobile {
            font-size: 32rem;
        }
    }

    &__description {
        color: $colorDark;
        font-weight: 300;
        font-size: 16rem;
        line-height: 1.4;
        text-align: center;

        @include mobile {
            max-width: 90%;
            line-height: 1.45;
        }
    }

    &__content {
        align-items: stretch;
        width: 100%;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            width: calc(100% + 12rem);
        }

        &Block {
            position: relative;
            z-index: 1;
            width: 43%;
            overflow: hidden;
            background: #fff;
            border-radius: 30rem 0 0 30rem;

            @include mobile {
                width: 100%;
                margin-bottom: 2rem;
                border-radius: 20rem 20rem 2rem 2rem;
            }

            &._content {
                padding: 68rem 60rem 68rem 86rem;

                @include mobile {
                    padding: 22rem;
                }
            }

            &._map {
                width: calc(100% - 43% - 2rem);
                margin-left: 2rem;
                border-radius: 0 30rem 30rem 0;

                @include mobile {
                    width: 100%;
                    height: 296rem;
                    margin-top: 2rem;
                    margin-left: 0;
                    border-radius: 2rem 2rem 20rem 20rem;
                }
            }
        }

        &Info {
            width: 100%;

            & + & {
                margin-top: 30rem;

                @include mobile {
                    margin-top: 26rem;
                }
            }

            &Value {
                margin-bottom: 7rem;
                font-weight: 300;
                font-size: 26rem;
                line-height: 1.4;

                @include mobile {
                    margin-bottom: 5rem;
                    font-size: 22rem;
                }
            }

            &Support {
                color: $colorGrey;
                font-weight: 300;
                font-size: 18rem;

                @include mobile {
                    font-weight: 500;
                    font-size: 16rem;
                }
            }

            &Button {
                align-items: center;
                height: 36rem;
                padding: 9rem 12rem 9rem 15rem;
                color: $colorMain;
                font-size: 16rem;
                background: #edf8ff;
                border-radius: 9rem;

                @include button(0.95);

                @include mobile {
                    justify-content: center;
                    width: calc(100% + 12rem);
                    height: 56rem;
                    margin-top: 3rem;
                    margin-left: -6rem;
                    font-size: 17rem;
                    border-radius: 16rem;
                }

                &Icon {
                    width: 14rem;
                    height: 14rem;
                    margin-left: 6rem;

                    @include mobile {
                        width: 20rem;
                        height: 20rem;
                        margin-left: 8rem;
                    }
                }
            }
        }
    }
}
