.order {
    width: 100%;
    height: 100%;
    padding: 32rem 26rem;
    background: #dde9ef;

    @include mobile {
        padding: 85rem 0 0;
        background: #eef4f7;
    }

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        background: #f3f7fb;
        border-radius: 26rem;

        @include mobile {
            background: none;
            border-radius: 0;
        }
    }

    &__info {
        position: absolute;
        top: 50%;
        left: -4rem;
        z-index: 2;
        width: 550rem;
        height: calc(100% + 14rem);
        padding: 52rem 68rem;
        background: #fff;
        border-radius: 26rem;
        box-shadow: 8rem 2rem 32rem rgba($colorDark, 0.08);
        transform: translate(0, -50%);

        @include mobile {
            position: relative;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: auto;
            padding: 0;
            background: none;
            border-radius: 0;
            box-shadow: none;
            transform: none;
        }

        &Logo {
            width: 156rem;
            margin-bottom: 110rem;
        }

        &Blocks {
            width: 100%;
            margin: auto 0;
        }

        &Block {
            top: 50%;
            padding: 0 10rem;
            transform: translate(0, -50%);
            will-change: transform;

            &:not(._show) {
                &._prev {
                    transform: translate(0, -50%) scale(0.9);
                }

                &._next {
                    transform: translate(0, -50%) scale(1.1);
                }
            }

            &Number {
                margin-bottom: 14rem;
                color: $colorMain;
                font-size: 32rem;
            }

            &Title {
                margin-bottom: 12rem;
                font-size: 36rem;
                line-height: 1.15;
            }

            &Description {
                max-width: 85%;
                font-weight: 300;
                font-size: 16rem;
                line-height: 1.5;
            }
        }

        &Steps {
            align-items: flex-end;
            justify-content: space-between;
            width: calc(100% - 60rem);
            margin-top: auto;
            padding: 10rem 12rem;
            transition: $trans;
            counter-reset: orderStepCounter;

            @include mobile {
                position: relative;
                z-index: 2;
                justify-content: center;
                width: 100%;
                width: auto;
                margin: 0 auto;
                padding: 0;
            }
        }

        &Step {
            position: relative;
            width: calc(calc(100% / 7) - 4rem);
            padding-bottom: 10rem;
            transition: $trans;
            counter-increment: orderStepCounter;

            @include mobile {
                align-items: center;
                width: 26rem;
                margin: 0 3.5rem;
                padding-bottom: 7rem;

                &::before {
                    padding: 0 6rem;
                    color: $colorMain;
                    font-weight: 500;
                    font-size: 18rem;
                    text-align: center;
                    opacity: 0;
                    transition: $trans;
                    content: "0" counter(orderStepCounter);
                }
            }

            @include button;

            &._current {
                &::before {
                    opacity: 1;
                }
            }

            &:not(._current) {
                // width: calc(calc(100% - 17%) / 6 - 4rem);
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2rem;
                background: $colorMain;
                border-radius: 360rem;
                transition: $trans;
                content: "";
            }

            &:not(._current)::after {
                opacity: 0.26;

                @include mobile {
                    opacity: 0.4;
                }
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                width: 16rem;
                height: 16rem;
                margin-top: -4rem;
                transform: translate(-50%, -50%);
                opacity: 0.26;
                transition: $trans;

                @include mobile {
                    width: 14rem;
                    height: 14rem;
                    margin-top: -4rem;
                }
            }

            &:not(._completed) &Icon {
                opacity: 0;
            }

            &Content {
                font-weight: 300;
                font-size: 11.5rem;
                line-height: 1.3;
                white-space: nowrap;
                text-transform: uppercase;
                transition: $trans;

                @include mobile {
                    display: none;
                }

                &._support {
                    font-weight: 400;
                }
            }

            &:not(._current) &Content {
                opacity: 0;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        width: calc(100% - 550rem);
        height: 100%;
        margin-left: auto;

        @include mobile {
            width: 100%;
            height: calc(100% - 25rem);
            margin-left: 0;
        }

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;
        }
    }

    &__loader {
        @include loader($colorMain);

        @include mobile {
            height: calc(100vh - 140rem);
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__pages {
        width: 100%;
        height: 100%;

        @include mobile {
            height: auto;
            min-height: 100%;
        }
    }

    &__page {
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 46rem;
        padding-bottom: 0;
        overflow: hidden;
        overflow-y: auto;
        border-radius: 26rem;
        will-change: transform;

        @include mobile {
            align-items: center;
            height: auto;
            min-height: calc(#{var(--wheight)} - 85rem - 25rem);
            padding: 16rem 12rem 21rem;
        }

        &:not(._show) {
            &._prev {
                transform: translate(-20rem, 0);
            }

            &._next {
                transform: translate(20rem, 0);
            }
        }

        &Head {
            align-items: center;
            margin-bottom: 16rem;
        }

        &Title {
            font-weight: 500;
            font-size: 26rem;
            line-height: 1.25;
            text-align: center;
        }

        &Description {
            max-width: 95%;
            font-weight: 300;
            font-size: 18rem;
            line-height: 1.5;
            text-align: center;
        }
    }

    &__title {
        width: 100%;
        margin-bottom: 28rem;

        & span {
            font-weight: 500;
            font-size: 21rem;
        }

        &Inner {
            display: inline-block;
            font-weight: 300;
            font-size: 21rem;
            line-height: 0.5;
            white-space: nowrap;
        }
    }

    &__route {
        width: 100%;
    }

    &__contacts {
        width: 100%;
    }

    &__foot {
        position: sticky;
        bottom: 0;
        z-index: 2;
        width: 100%;
        margin-top: auto;
        padding-top: 28rem;
        padding-bottom: 46rem;

        @include mobile {
            position: relative;
            margin-top: auto;
            padding-top: 32rem;
            padding-bottom: 0;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: -20rem;
            z-index: -1;
            width: calc(100% + 40rem);
            height: calc(100% + 120rem);
            background: linear-gradient(to top, #f3f7fb, #f3f7fb 46rem, rgba(#f3f7fb, 0) 100%);
            opacity: 0;
            transition: $trans;
            content: "";
            pointer-events: none;

            @include mobile {
                display: none;
            }
        }

        &._scroll::after {
            opacity: 1;
        }

        &Error {
            width: 100%;
            margin-left: auto;

            &._parent:not(._empty) {
                margin-top: 20rem;
            }

            & .error {
                text-align: right;

                @include mobile {
                    text-align: center;
                }
            }
        }

        &Inner {
            align-items: center;
            width: 100%;

            @include mobile {
                flex-direction: column-reverse;
            }

            // box-shadow: 0 2rem 8rem rgba(#000, .1);
        }

        &Block {
            align-items: center;
            height: 44rem;

            @include mobile {
                flex-direction: column;
                width: 100%;
                height: auto;
            }

            &._prev {
                margin-right: auto;

                @include mobile {
                    position: relative;
                    z-index: 2;
                    margin-top: 12rem;
                    margin-right: 0;
                }
            }
        }

        &Button {
            height: 100%;

            @include mobile {
                width: 100%;
                height: 56rem;
            }

            &._next {
                width: 174rem;

                @include mobile {
                    width: 100%;
                }
            }
        }

        &Info {
            align-items: center;
            padding-right: 24rem;

            @include mobile {
                padding-right: 0;

                &._parent {
                    margin-bottom: 20rem;
                }
            }

            &Inner {
                @include mobile {
                    flex-direction: column;
                    align-items: center;
                }
            }

            &Item {
                align-items: center;
                font-weight: 300;
                font-size: 15rem;
                white-space: nowrap;

                @include mobile {
                    font-size: 18rem;
                }

                &._grey {
                    color: $colorGrey;
                    font-weight: 400;
                }

                & + & {
                    margin-left: 14rem;

                    @include mobile {
                        margin-top: 16rem;
                        margin-left: 0;
                    }
                }

                & span {
                    font-weight: 400;
                    font-size: 15rem;

                    @include mobile {
                        font-size: 18rem;
                    }
                }
            }
        }
    }
}
