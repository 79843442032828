.cursorActions {
    position: absolute;
    z-index: 10000;

    &__item {
        position: absolute;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Action {
            position: relative;
            padding: 14rem 16rem 14rem 36rem;
            color: $colorDark;
            font-weight: 300;
            font-size: 15rem;
            white-space: nowrap;
            background: #fff;
            border-radius: 2rem;
            box-shadow: 0 2rem 8rem rgba(#000, 0.05);

            @include mobile {
                padding-left: 38rem;
                font-size: 16rem;
                box-shadow: 0 2rem 8rem rgba(#000, 0.072);
            }

            @include button(0.96);

            &:not(:last-child) {
                margin-bottom: 1rem;

                @include mobile {
                    margin-bottom: 0;
                    border-bottom: 1px solid #eee;
                }
            }

            &:first-child {
                border-top-left-radius: 14rem;
                border-top-right-radius: 14rem;
            }

            &:last-child {
                border-bottom-right-radius: 14rem;
                border-bottom-left-radius: 14rem;
            }

            &._delete {
                color: $colorAlert;
            }

            &Loader {
                background: inherit;
                border-radius: inherit;

                @include loader($colorMain);

                & ._LOADERITEM {
                    width: 20rem;
                    height: 20rem;
                }
            }

            @include hover {
                &:hover {
                    color: $colorMain;

                    @include icon($colorMain);
                }
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 12rem;
                width: 18rem;
                height: 18rem;
                transform: translate(0, -50%);

                @include mobile {
                    width: 20rem;
                    height: 20rem;
                }
            }
        }
    }
}
