.body {
    @extend ._NOSCROLL;

    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
    overscroll-behavior: none;
    text-size-adjust: none;

    &._isDrag {
        user-select: none;

        & * {
            user-select: none;
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        background: #f3f7f9;
        transition: $trans;

        @include mobile {
            background: #eef4f7;
        }

        &Inner {
            width: 100%;
            height: 100%;

            &._grey {
                background: #f3f7f9;

                @include mobile {
                    background: #eef4f7;
                }
            }
        }
    }

    &__topBar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1003;
        width: 100%;
        pointer-events: none;

        &._hide {
            visibility: hidden;
            opacity: 0;
        }

        @include mobile {
            height: 80rem;
        }
    }

    &__publicMenu {
        position: fixed;
        z-index: 1002;
        width: 100%;
        height: 100%;
    }

    &__pages {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &._visible {
            overflow: visible;
        }
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        transform-origin: center top;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
            pointer-events: none;

            &._profileInner {
                &._prev {
                    transform: translate(-50rem, 0);
                }

                &._next {
                    transform: translate(50rem, 0);
                }
            }
        }

        &._popup {
            z-index: 1003;
        }

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Loader {
            @include loader($colorMain);
        }
    }

    &__sections {
        width: 100%;
    }

    &__section {
        width: 100%;
    }

    &__popup {
        position: fixed;
        z-index: 1004;
        width: 100%;
        height: 100%;
        transition: $trans;

        &:not(._alert, ._filter) {
            @include mobile {
                background: none;
                backdrop-filter: unset;
                transition: none;
            }
        }

        &:not(._show) {
            opacity: 0;
        }

        &:not(._alert, ._filter, ._show) {
            @include mobile {
                opacity: unset;
            }
        }
    }

    &__dayActions {
        position: fixed;
        bottom: 30rem;
        left: 50%;
        z-index: 100;
        display: flex;
        height: 60rem;
        transform: translate(-50%, 0);
    }

    &__dayAction {
        width: 250rem;
        height: 100%;

        & + & {
            margin-left: 20rem;
        }
    }

    &__legalNav {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16rem 20rem 17rem;
        color: #fff;
        font-size: 18rem;
        background: $colorMain;
        border-top: 2rem solid #fff;
        border-left: 2rem solid #fff;
        border-radius: 24rem 0 0;
        box-shadow: 0 4rem 8rem rgba($colorDark, 0.05);
        transform-origin: right bottom;
        user-select: none;

        @include button(0.96);

        &::after {
            display: block;
            margin-top: -3rem;
            margin-left: 10rem;
            border: 3rem solid transparent;
            border-right-color: #fff;
            border-bottom-color: #fff;
            transform: rotate(45deg);
            content: "";
        }
    }

    &__chatBtn {
        position: absolute;
        right: 20rem;
        bottom: 20rem;
        z-index: 10;
        width: 60rem;
        height: 60rem;
    }
}
