.publicLegalNav {
    width: 100%;

    &__head {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;
        padding: 30rem 36rem;
        color: $colorDark;
        font-weight: 400;
        font-size: 20rem;
        background: #fff;
        border-radius: 24rem 24rem 0 0;
        transition: $trans;

        @include mobile {
            margin-bottom: 32rem;
            padding: 0;
            font-size: 22rem;
            background: none;
        }

        &Loader {
            position: absolute;
            top: 50%;
            right: 32rem;
            width: 24rem;
            height: 24rem;
            transform: translate(0, -50%);

            @include loader($colorMain);

            @include mobile {
                right: 0;
            }
        }
    }

    &:not(._ready) &__head {
        border-radius: 24rem;
    }

    &__content {
        width: 100%;
        padding: 0 36rem;
        background: rgba(#fff, 0.48);
        border-radius: 0 0 24rem 24rem;

        @include mobile {
            padding: 0;
            background: none;
            border-radius: 0;
        }
    }

    &__item {
        width: 100%;
        padding: 22rem 0;

        @include mobile {
            padding: 0;
        }

        & + & {
            border-top: 1rem solid rgba($colorGrey, 0.32);

            @include mobile {
                margin-top: 16rem;
                padding-top: 16rem;
            }
        }
    }

    &__link {
        color: $colorDark;
        font-weight: 300;
        font-size: 16rem;
        line-height: 1.35;

        @include button(0.97);

        @include mobile {
            font-size: 16rem;
        }

        &._current {
            color: $colorMain;
            font-weight: 400;
        }
    }
}
